import { GetBaseApiEndPoint } from "./../appConfig";
import axios from "axios";
const apibase = GetBaseApiEndPoint();

export function questionDetails() {  
  return axios
    .get(`${apibase}questionnaire`)
    .then(response => response.data)
    .catch(error => {
      //error.response.status === 401;
      throw error;
    });
}

// export function questionAnswerApi(updateData) {
//   return new Promise((resolve, reject) => {
//     fetch(apibase + "patient-questionnaire", {
//       method: "POST",
//       body: JSON.stringify(updateData)
//     })
//       .then(response => response.json())
//       .then(res => {
//         resolve(res);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// }

export function questionAnswerApi(updateData) {
  return axios
    .get(`${apibase}patient-questionnaire`, updateData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
