/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Snackbar = lazy(() => import("components/Snackbar/SnackbarContent"));

import image from "../../assets/img/subscribe1.svg";

import {
  selfAssessment,
  selfAssessmentClearFlag,
} from "../../redux/actions/SelfAssessmentAction";

import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

class SelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      response: "",
      variant: "error",
      openSnackBar: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  static getDerivedStateFromProps(props, state) {
    const {
      selfAssessmentSuccess,
      selfAssessmentClearFlag,
      selfAssessmentError,
      selfAssessmentData,
    } = props;
    if (selfAssessmentSuccess) {
      selfAssessmentClearFlag();
      props.history.push("/self-assessment-test-details");
    }
    if (selfAssessmentError) {
      selfAssessmentClearFlag();
      return {
        openSnackBar: true,
        response: "The email has already been taken.",
      };
    }
    return null;
  }

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  submitAssessmentData = (values) => {
    const { selfAssessment, client } = this.props;
    const { name, email, phone } = values;
    const selfAssessmentDetails = {
      name,
      email,
      mobile_no: phone,
    };
    selfAssessment(selfAssessmentDetails, client);
  };

  render() {
    const { classes } = this.props;

    const { email, name, response, variant, openSnackBar, phone } = this.state;

    return (
      <>
        <Suspense fallback={<div></div>}>
          <div className={classes.signUpMainCard}>
            <div className="selfAssessmentForm">
              <Header
                absolute
                color="white"
                links={<HeaderLinks dropdownHoverColor="info" />}
              />
              <div>
                <div
                  className={classes.container + " " + classes.signUpContainer}
                >
                  <Snackbar
                    open={openSnackBar}
                    handleClose={this.handleCloseSnackbar}
                    variant={variant}
                    message={response}
                  />
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Card
                        className={classes.cardSignup}
                        style={{ padding: "0" }}
                      >
                        <div style={{ textAlign: "center" }}></div>
                        <CardBody>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                              <img
                                src={image}
                                alt="self-assessment"
                                className={classes.signUpImage}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={1}></GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <h2 className={classes.cardTitle}>
                                Self Assessment
                              </h2>
                              <Formik
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                  email: Yup.string()
                                    .required("Email is required")
                                    .email("Email is invalid"),
                                  name: Yup.string().required(
                                    "Name is required"
                                  ),
                                  phone: Yup.string().required(
                                    "Phone number is required"
                                  ),
                                })}
                                initialValues={{
                                  email,
                                  name,
                                  phone,
                                }}
                                onSubmit={(values) => {
                                  this.submitAssessmentData(values);
                                }}
                              >
                                {({
                                  errors,
                                  values,
                                  touched,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                  <>
                                    <div className={classes.SignUpFormDiv1}>
                                      <form>
                                        <div>
                                          <TextField
                                            fullWidth
                                            placeholder={"Full Name"}
                                            name="name"
                                            margin="normal"
                                            variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                          />
                                          {errors.name && touched.name && (
                                            <FormHelperText error>
                                              {errors.name}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div>
                                          <TextField
                                            fullWidth
                                            placeholder={"Email"}
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                          />
                                          {errors.email && touched.email && (
                                            <FormHelperText error>
                                              {errors.email}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div
                                          style={{
                                            marginBottom: 10,
                                            marginTop: 15,
                                          }}
                                        >
                                          <MuiPhoneNumber
                                            fullWidth
                                            name="phone"
                                            variant="outlined"
                                            disableAreaCodes={true}
                                            onBlur={handleChange}
                                            defaultCountry={"gb"}
                                            onChange={handleChange}
                                            countryCodeEditable={true}
                                            value={values.phone}
                                          />
                                          {errors.phone && touched.phone && (
                                            <FormHelperText error>
                                              {errors.phone}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </form>

                                      <div className={classes.textCenter}>
                                        <Button
                                          fullWidth
                                          onClick={handleSubmit}
                                          className={classes.button}
                                          variant="contained"
                                          color="theme"
                                          style={{
                                            marginTop: "30px",
                                          }}
                                        >
                                          Start Assessment
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Formik>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </>
    );
  }
}
SelfAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selfAssessmentSuccess:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.selfAssessmentSuccess,
    selfAssessmentError:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.selfAssessmentError,
    selfAssessmentData:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.selfAssessmentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selfAssessment,
      selfAssessmentClearFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(SelfAssessment)));
