/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

import FormControl from "@material-ui/core/FormControl";
import Container from "@material-ui/core/Container";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Snackbar } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Button = lazy(() => import("components/CustomButtons/Button"));
const Footer = lazy(() => import("../../components/Footer/Footer"));

import { questionDetails } from "../../services/Questions";
import { postRequestWithOutToken } from "../../services/signUp";

import { Options } from "constants/Data";

import styles from "assets/jss/material-kit-pro-react/views/questionStyle";

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      questionAnswers: [],
      saveQuestion: false,
      openSnackBar: false,
      activeClass: "normal",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onScrollHandle();
    questionDetails().then((result) => {
      this.setState({
        questionAnswers: result,
      });
    });
  }

  onScrollHandle = () => {
    window.addEventListener("scroll", () => {
      const activeClass = window.scrollY >= 90 ? "fixed" : "normal";
      this.setState({ activeClass });
    });
  };

  handleDropdown = () => {
    this.setState((preState) => ({
      snackbarOpen: !preState.snackbarOpen,
    }));
  };

  onSubmitQuestions = () => {
    const { questionAnswers } = this.state;

    const newData = questionAnswers.map((item) => {
      if (item.answer === undefined) {
        return {
          questionnaire_id: item.id,
          answer: "0",
        };
      } else {
        return {
          questionnaire_id: item.id,
          answer: item.answer,
        };
      }
    });
    const data1 = {
      patient_id: localStorage.getItem("id"),
      questions: newData,
    };

    postRequestWithOutToken("patient-questionnaire", data1)
      .then((res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          this.props.history.push("/response", { data: res });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  handleCheckChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    const { questionAnswers, questionAlert, openSnackBar } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Suspense fallback={<div />}>
          <div className={classes.bgImage}>
            <div>
              <Header
                fixed
                color="white"
                links={<HeaderLinks dropdownHoverColor="info" />}
              />
              <Container maxWidth="lg" className={classes.containerDiv}>
                <div className={classes.mainDiv}>
                  <h2 className={classes.fontBold}>Questionnaire</h2>
                  <Container maxWidth="lg">
                    <div
                      className={`${classes.scoreDescription} ${this.state.activeClass} ${classes.divQuestions} ${classes.margin_bottom} `}
                    >
                      <p className={classes.textJustify}>
                        Score : 1 = None, 2 = Mild, 3 = Moderate, 4 = Severe, 5
                        = Extremely Severe
                      </p>
                    </div>
                    <Snackbar
                      className="snackbar"
                      message={`Please answer mandatory question. Question numbers are ${questionAlert}`}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={openSnackBar}
                      onClose={this.handleCloseSnackbar}
                      autoHideDuration={6000}
                    />
                    {questionAnswers.map((question, index) => (
                      <div className={classes.margin_bottom} key={question.id}>
                        <div className={classes.divQuestions}>
                          <h4 className={classes.questionDiv}>
                            {index + 1}. {question.question}
                          </h4>
                          <div className={classes.scoreCheckBox}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                name="score"
                                value={question.answer}
                                defaultValue="0"
                                onChange={(e) => {
                                  questionAnswers[index].answer =
                                    e.target.value;
                                  this.setState({ questionAnswers });
                                }}
                              >
                                {Options.map((option, index) => (
                                  <Tooltip
                                    title={option.title}
                                    key={option.title + index}
                                  >
                                    <FormControlLabel
                                      value={option.value}
                                      label={option.value}
                                      control={<Radio color="primary" />}
                                      labelPlacement={"start"}
                                    />
                                  </Tooltip>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className={classes.submitDiv}>
                      <Button
                        onClick={this.onSubmitQuestions}
                        variant="contained"
                        color="theme"
                        className={classes.submitButton}
                      >
                        Submit
                      </Button>
                    </div>
                  </Container>
                </div>
              </Container>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Questions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Questions);
