/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Mail from "@material-ui/icons/Mail";
import { IconButton, FormControl } from "@material-ui/core";
import { Link } from "react-router-dom";

const Button = lazy(() => import("components/CustomButtons/Button.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const GridItem = lazy(() => import("components/Grid/GridItem"));

import { Links } from "constants/Data";

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");

  function handleChange(e) {
    setEmail(e.target.value);
  }

  return (
    <div>
      <Suspense fallback={<div />}>
        <div className={classes.footer + " " + classes.bgFooter}>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={4} md={3} className={classes.grid}>
              <Link to="/">
                <h5>Menergy</h5>
              </Link>
              <p className={classes.footerContent}>
                Menergy provides medically supervised hormone replacement of
                testosterone deficiencies.
              </p>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} className={classes.grid}>
              <h5>About</h5>
              <ul
                className={classes.linksVertical + " " + classes.footerContent}
              >
                <li>
                  <Link to="/"> Blog </Link>
                </li>
                <li>
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/about-us/",
                      hash: "#Team",
                    }}
                  >
                    Team
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/",
                      hash: "#ContactUs",
                    }}
                  >
                    Contact us{" "}
                  </Link>
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} className={classes.grid}>
              <h5>Self Assessment</h5>
              <ul
                className={classes.linksVertical + " " + classes.footerContent}
              >
                <li>
                  <Link to="/self-assessment">Self Assessment</Link>
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} className={classes.grid}>
              <h5>Legal</h5>
              <ul
                className={classes.linksVertical + " " + classes.footerContent}
              >
                <li>
                  <Link to="/transactions-faq">Transactions FAQ</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Terms & conditions</Link>
                </li>
                <li>
                  <Link to="/licenses">Licenses</Link>
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={4} md={3} className={classes.grid}>
              <h5>Subscribe to Newsletter</h5>
              <p className={classes.footerContent}>
                Join our newsletter and get news in your inbox every week!
              </p>
              <FormControl className="footer-form">
                <InputLabel htmlFor="Email">Enter Email</InputLabel>
                <Input
                  id="Email"
                  value={email}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <Mail style={{ color: "#fff" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.divFlex}>
          <ul className={classes.socialButtons}>
            {Links.map((link, index) => (
              <li key={link.class + index}>
                <Link to="/">
                  <Button justIcon simple color={link.color}>
                    <i className={link.class} />
                  </Button>
                </Link>
              </li>
            ))}
          </ul>
          <div className={classNames(classes.pullCenter, classes.copyRight)}>
            Copyright &copy; {1900 + new Date().getYear()}{" "}
            <span style={{ color: "#1c313a", fontWeight: "bold" }}>
              Menergy
            </span>{" "}
            All Rights Reserved.
          </div>
        </div>
      </Suspense>
    </div>
  );
}
