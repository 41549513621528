import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_ERROR,
  CLEAR_ORDER_FLAG
} from "../type/OrderType";
// import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  isOrderSuccess : false
}

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    // case REHYDRATE: {
    //   return { ...action.payload.orderReducer };
    // }
    case ORDER_REQUEST:
      return {
        ...state
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        isOrderSuccess: true
      };
    case ORDER_ERROR:
      return {
        ...state
      };
    case CLEAR_ORDER_FLAG:
      return {
        ...state,
        isOrderSuccess: false
      };
    default:
      return {
        ...state
      };
  }
}
