/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import { Tooltip } from "@material-ui/core";

const Header = lazy(() => import("components/Header/Header.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const UserSettings = lazy(() =>
  import("components/UserSettings/UserSettings.js")
);
const ProfileTabs = lazy(() => import("./ProfileTabs"));

import UserImage from "assets/img/placeholder.png";

import styles from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openUserSetting: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleOpenUserSetting = () => {
    const { openUserSetting } = this.state;
    this.setState({
      openUserSetting: !openUserSetting,
    });
  };

  render() {
    const { classes, userData } = this.props;
    const { openUserSetting } = this.state;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <>
        <Suspense fallback={<div />}>
          <div>
            <Header
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              changeColorOnScroll={{
                height: 200,
                color: "white",
              }}
            />
            <Parallax
              image={require("assets/img/img3.jpg")}
              filter="dark"
              className={classes.parallax}
              
            />
            <UserSettings
              open={openUserSetting}
              handleOpen={this.handleOpenUserSetting}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
              <div
                className={classes.container}
                style={{ paddingBottom: "50px" }}
              >
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.profile}>
                      <div>
                        <img
                          src={UserImage}
                          alt="Menergy-patient"
                          className={imageClasses}
                        />
                      </div>
                      <div className={classes.name}>
                        <h3 className={classes.title}>
                          {" "}
                          {userData && userData.name}
                        </h3>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <div
                  className={classes.settingIconContainer}
                  onClick={this.handleOpenUserSetting}
                >
                  <Tooltip title="Change Settings">
                    <SettingsIcon />
                  </Tooltip>
                </div>
                <div className={classes.profileTabs}>
                  <ProfileTabs {...this.props} />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userData: state.logInReducer && state.logInReducer.loginData.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withApollo(withStyles(styles)(Profile)));
