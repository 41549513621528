import {
    SELF_ASSESSMENT_REQUEST,
    SELF_ASSESSMENT_SUCCESS,
    SELF_ASSESSMENT_ERROR,
    CLEAR_SELF_ASSESSMENT_FLAG
  } from "../type/SelfAssessmentType";
  import { SelfAssessment } from "../../graphql/Mutations/SelfAssessmentMutation";
  
  export const selfAssessmentRequest = payload => {
    return {
      type: SELF_ASSESSMENT_REQUEST,
      payload
    };
  };
  
  export const selfAssessmentSuccess = payload => {
    return {
      type: SELF_ASSESSMENT_SUCCESS,
      payload
    };
  };
  
  export const selfAssessmentError = payload => {
    return {
      type: SELF_ASSESSMENT_ERROR,
      payload
    };
  };
  
  export const selfAssessment = (values, client) => {
    return dispatch => {
      dispatch(selfAssessmentRequest());
      return client
        .mutate({
          variables: values,
          mutation: SelfAssessment,
        })
        .then(result => dispatch(selfAssessmentSuccess(result)))
        .catch(error => dispatch(selfAssessmentError(error)));
    };
  };
  
  export const selfAssessmentClearFlag = () => {
    return {
      type: CLEAR_SELF_ASSESSMENT_FLAG
    };
  };
  