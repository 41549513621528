import { primaryMainColor } from "assets/jss/material-kit-pro-react";
import {
  secondaryMainColor,
  whiteColor
} from "assets/jss/material-kit-pro-react.js";

const styles = {
  Logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0 0 10px 0",
    padding: "20px"
  },
  SignUpFormDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  form_Control: {
    marginLeft: 0,
    marginRight: 0,
    color: primaryMainColor,
    width: "25%"
  },
  margin_bottom: {
    marginBottom: 35
  },
  radio_button: {
    color: primaryMainColor + "!important"
  },
  formTitle: {
    textAlign: "center",
    color: whiteColor
  },
  fontBold: {
    fontWeight: 500,
    lineHeight: "80px",
    margin: "10px 0",
    color: primaryMainColor,
    textAlign: "center",
    marginBottom: "30px"
  },
  mainDiv: {
    marginTop: "30px",
    paddingBottom: 20
  },
  divLine: {
    backgroundColor: primaryMainColor,
    height: "100px",
    width: "85%",
    marginTop: "-5%"
  },
  divWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  bgImage: {
    backgroundColor: "white"
  },
  divQuestions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    boxShadow: "2px 3px 15px #00000030",
    flexWrap: "wrap"
  },
  scoreDescription: {
    background: "white",
    justifyContent: "center",
    boxShadow: "none",
    "& p": {
      color: primaryMainColor,
      fontSize: "1.2rem",
      fontWeight: "500",
      marginTop: 10
    }
  },
  scoreCheckBox: {
    marginRight: 15,
    display: "flex",
    "@media (max-width: 768px)": {
      justifyContent: "space-between",
      width: "100%"
    },
    "& div": {
      flexDirection: "row",
      "@media (max-width: 768px)": {
        marginRight: 0
      }
    }
  },
  questionDiv: {
    fontWeight: "500",
    width: "60%",
    "@media (max-width: 1020px)": {
      width: '100%'
    }
  },
  submitDiv: {
    marginBottom: "20px",
    textAlign: "center",
    marginTop: "20px"
  },
  submitButton: {
    marginBottom: "10px",
    backgroundColor: secondaryMainColor,
    color: "white",
    fontSize: "1.05rem"
  },
  containerDiv: {
    paddingTop: "12vh",
    paddingBottom: "50px"
  },
  textJustify: {
    textAlign: 'justify'
  }
};

export default styles;
