/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Button = lazy(() => import("components/CustomButtons/Button"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const Footer = lazy(() => import("components/Footer/Footer"));
const Accordion = lazy(() => import("components/Accordion/Accordion.js"));

import UserImage from "assets/img/placeholder.png";

import styles from "assets/jss/material-kit-pro-react/views/profileSetting/profileSettingStyles";

class ProfileSetting extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goToPackages = () => {
    this.props.history.push("/packages");
  };

  goToUserProfile = () => {
    this.props.history.push("/my-profile/you");
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <Suspense fallback={<div />}>
          <div className={classes.mainWhiteBackground}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />

            <div>
              <div
                className={
                  classes.container + " " + classes.profileCustomContainer
                }
              >
                <h2 className={classes.backArrowPositionTitle + " " + classes.title}> <ArrowBackIcon onClick={this.goToUserProfile} /> Subscription Setting </h2>

                <GridContainer>
                  <GridItem lg={4} md={4}>
                    <div className={classes.sectionProfileImage}>
                      <img src={UserImage} alt={"menergy-user-image"} />
                      <p>Demo User</p>
                      <p className={classes.userGmail}>demouser@gmail.com</p>
                    </div>
                  </GridItem>
                  <GridItem lg={8} md={8}>
                    <div className={classes.sectionProfileDetails}>
                      <p className={classes.sectionProfileDetailsTitle}>
                        Subscribe Detail
                      </p>
                      <GridContainer className={classes.subscriptionCustomGrid}>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsTitle}>
                            Package Type
                          </p>
                        </GridItem>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsValue}>
                            {" "}
                            Yearly{" "}
                          </p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsTitle}>
                            {" "}
                            Package Name{" "}
                          </p>
                        </GridItem>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsValue}>
                            {" "}
                            Premium{" "}
                          </p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsTitle}>
                            {" "}
                            Start Date{" "}
                          </p>
                        </GridItem>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsValue}>
                            {" "}
                            20/06/2020{" "}
                          </p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsTitle}>
                            {" "}
                            End Date{" "}
                          </p>
                        </GridItem>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsValue}>
                            {" "}
                            20/06/2021{" "}
                          </p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsTitle}>
                            {" "}
                            Order Number{" "}
                          </p>
                        </GridItem>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsValue}>
                            {" "}
                            101012002{" "}
                          </p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsTitle}>
                            {" "}
                            Account{" "}
                          </p>
                        </GridItem>
                        <GridItem sm={6} xs={6} md={6} lg={4}>
                          <p className={classes.subscriptionDetailsValue}>
                            {" "}
                            demouser@gmail.com{" "}
                          </p>
                        </GridItem>
                      </GridContainer>

                      <Button
                        color="theme"
                        size="sm"
                        style={{ marginTop: "10px" }}
                        onClick={this.goToPackages}
                      >
                        Change Package
                      </Button>
                      <p
                        className={
                          classes.sectionProfileDetailsTitle +
                          " " +
                          classes.sectionProfileDetailsSubTitle
                        }
                      >
                        Past Packages
                      </p>
                      <GridContainer
                        className={
                          classes.customPastGridContainer +
                          " " +
                          classes.subscriptionCustomGrid
                        }
                      >
                        <GridItem sm={10} xs={12} md={10} lg={6}>
                          <Accordion
                            activeColor="theme"
                            collapses={[
                              {
                                title: "QUARTERLY - BASIC",
                                content: (
                                  <div>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          Package Type
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Quarterly{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Package Name{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Basic{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Start Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/02/2020{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          End Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/05/2021{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Order Number{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          1101012002{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                  </div>
                                ),
                              },
                              {
                                title: "QUARTERLY - PREMIUM",
                                content: (
                                  <div>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          Package Type
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Quarterly{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Package Name{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Premium{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Start Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/10/2019{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          End Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/01/2020{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Order Number{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          102012002{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                  </div>
                                ),
                              },
                              {
                                title: "MONTHLY - PREMIUM PLUS",
                                content: (
                                  <div>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          Package Type
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Monthly{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Package Name{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Premium Plus{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Start Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/08/2019{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          End Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/09/2019{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Order Number{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          101012020{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                  </div>
                                ),
                              },
                              {
                                title: "MONTHLY - BASIC",
                                content: (
                                  <div>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          Package Type
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Monthly{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Package Name{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          Basic{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Start Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/06/2019{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          End Date{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          20/07/2019{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsTitle
                                          }
                                        >
                                          {" "}
                                          Order Number{" "}
                                        </p>
                                      </GridItem>
                                      <GridItem md={6} lg={6} sm={6} xs={6}>
                                        <p
                                          className={
                                            classes.subscriptionDetailsValue
                                          }
                                        >
                                          {" "}
                                          101012123{" "}
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </div>
    );
  }
}

ProfileSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileSetting);
