import {
  secondaryMainColor,
  whiteColor,
  secondaryDarkColor,
  secondaryLightColor,
  primaryMainColor,
} from "assets/jss/material-kit-pro-react.js";

const responseStyles = {
  divFlex: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "150px",
    paddingBottom: "150px",
  },
  fontSize: {
    fontSize: "1.3rem",
    margin: "10px 0px",
    fontWeight: "600",
  },
  fontSize2: {
    fontSize: "1rem",
    margin: "10px 0px",
    fontWeight: "600",
  },
  link: {
    color: "inherits",
    textDecoration: "none",
  },
  buttonCenter: {
    textAlign: "center",
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow: "1px 2px 5px 0px #00000040",
    marginBottom: "100px",
    padding: "40px 0px",
  },
  divLine: {
    backgroundColor: primaryMainColor,
    height: "100px",
    width: "85%",
    position: "absolute",
    top: "-5%",
  },
  divWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  cardTitle: {
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem",
    color: whiteColor + "!important",
    lineHeight: "80px",
    fontFamily: "inherit !important",
    margin: "10px 0",
    fontWeight: "500",
  },
  divResult: {
    textAlign: "center",
    padding: 80,
  },
  fontStyle: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: secondaryDarkColor,
  },
  result: {
    textAlign: "center",
  },
  cardCategory: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    textAlign: "center",
  },
  divResultFlex: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  testKitImage: {
    width: "100%",
  },
  widthHr: {
    width: "80%",
  },
  testKit: {
    display: "flex",
    alignItems: "center",
  },
  divInfo: {
    display: "flex",
    flexDirection: "column",
  },
  kitDetails: {
    color: secondaryLightColor,
    fontSize: "0.9rem",
    fontWeight: "400",
  },
  mainDiv: {
    backgroundColor: whiteColor,
    minHeight: "100vh",
  },
  buttonFinish: {
    marginTop: "30px",
    backgroundColor: secondaryMainColor,
    color: whiteColor,
  },
  divPrice: {
    textAlign: "center",
    marginLeft: "30px",
    fontSize: "1.8rem",
    fontWeight: "500",
    marginBottom: "0px",
  },
  buttonBuy: {
    backgroundColor: secondaryMainColor,
    color: whiteColor,
    padding: "10px 25px",
  },
  buttonFinishPurchase: {
    backgroundColor: secondaryMainColor,
    marginLeft: "30px",
    color: whiteColor,
    padding: "10px 25px",
  },
  marginTop0: {
    marginTop: 0,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
};

export default responseStyles;
