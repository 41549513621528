import {
  container,
  mlAuto,
  section,
  main,
  mainRaised,
  title,
  cardTitle,
  grayColor,
  themeColor,
  logoColor,
  secondaryLightColor,
  secondaryDarkColor,
  secondaryMainColor,
} from "assets/jss/material-kit-pro-react.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import { borderGrayColor } from "assets/jss/material-kit-pro-react";
import { dangerColor } from "assets/jss/material-kit-pro-react";
import { lightGrayColor } from "assets/jss/material-kit-pro-react";

const productStyle = {
  mlAuto,
  main,
  ...imagesStyles,
  ...customSelectStyle,
  ...tooltipsStyle,
  container: {
    ...container,
    zIndex: 2,
  },
  mainRaised: {
    ...mainRaised,
    boxShadow: "none",
  },
  section: {
    ...section,
    padding: "70px 0px",
  },
  title: {
    ...title,
    marginBottom: 0,
    fontSize: "1.3rem",
    fontFamily: "inherit",
    "@media (max-width: 768px)": {
      marginTop: "0",
    },
  },
  sectionGray: {
    background: grayColor[14],
  },
  mainPrice: {
    marginTop: "30px",
    marginBottom: "0px",
    "@media (max-width: 768px)": {
      marginTop: "0",
    },
  },
  textCenter: {
    textAlign: "center!important",
  },
  features: {
    marginTop: "40px",
    paddingTop: "30px",
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  productPage: {
    backgroundColor: "white",
    "& $mainRaised": {
      padding: "40px",
      paddingTop: "15vh",
      margin: "0 !important",
    },
    "& .image-gallery-slide img": {
      borderRadius: "3px",
      maxWidth: "300px",
      height: "auto",
    },
    "& .image-gallery-swipe": {
      margin: "30px 0px",
      overflow: "hidden",
      width: "100%",
      height: "auto",
      textAlign: "center",
    },
    "& .image-gallery-thumbnails > .image-gallery-thumbnails-container a": {
      "&.active > div": {
        opacity: "1",
        border: "1px solid #000",
      },
      "& > div": {
        width: "80%",
        maxWidth: "85px",
        margin: "10px 0",
        display: "block",
        border: "1px solid transparent",
        background: "transparent",
        opacity: ".7",
        "@media (max-width: 768px)": {
          margin: "0 auto",
        },
      },
      "& > div img": {
        width: "100%",
        height: "auto",
        textAlign: "center",
        margin: 0,
      },
    },
  },
  titleRow: {
    marginTop: "-8vh",
  },
  floatRight: {
    float: "right!important",
  },
  pageHeader: {
    minHeight: "60vh",
    maxHeight: "600px",
    height: "auto",
    backgroundPosition: "top center",
  },
  relatedProducts: {
    marginTop: "50px",
    "& $title": {
      marginBottom: "80px",
    },
  },
  pickSize: {
    marginTop: "50px",
    padding: "10px 0",
  },
  pullRight: {
    float: "right",
  },
  cardCategory: {
    textAlign: "center",
    marginTop: "10px",
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    color: "white !important",
    lineHeight: "80px",
    fontFamily: "inherit !important",
  },
  cardDescription: {
    textAlign: "center",
    color: secondaryLightColor,
  },
  textRose: {
    color: themeColor[0],
  },
  justifyContentBetween: {
    justifyContent: "space-between!important",
  },
  socialFeed: {
    "& p": {
      display: "table-cell",
      verticalAlign: "top",
      overflow: "hidden",
      paddingBottom: "10px",
      maxWidth: 300,
    },
    "& i": {
      fontSize: "20px",
      display: "table-cell",
      paddingRight: "10px",
    },
  },
  img: {
    width: "20%",
    marginRight: "5%",
    marginBottom: "5%",
    float: "left",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    top: "3px",
    width: "18px",
    height: "18px",
    position: "relative",
  },
  divLine: {
    backgroundColor: secondaryMainColor,
    height: "100px",
    width: "85%",
    position: "absolute",
    top: "-50px",
  },
  divWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  aboutSection: {
    marginBottom: "40px",
  },
  divTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
    "@media (max-width: 768px)": {
      marginBottom: "0",
    },
  },
  divGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  howItWorks: {
    // marginTop: '100px',
    fontWeight: "500",
    textTransform: "uppercase",
    marginBottom: "60px",
    "@media (max-width: 768px)": {
      marginTop: "50px",
    },
  },
  desc: {
    fontSize: "1rem",
    fontWeight: "400",
    color: secondaryDarkColor,
  },
  mb0: {
    marginBottom: 0,
  },
  shipping: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "35px",
    paddingBottom: "10px",
    borderBottom: "1px solid" + borderGrayColor,
    "@media (max-width: 768px)": {
      marginBottom: "10px",
    },
  },
  ulDesc: {
    fontWeight: "400",
    color: logoColor,
    listStyle: "none",
    paddingLeft: "10px",
    "& li": {
      paddingTop: "10px",
      display: "flex",
      alignItems: "center",
    },
  },
  iconCheck: {
    marginRight: "5px",
    color: "green",
  },
  mainGrid: {
    paddingTop: "10px",
    padding: "80px 30px",
  },
  size: {
    height: "25px !important",
    width: "25px !important",
  },
  inclusion: {
    padding: "50px 0",
    paddingLeft: "30px",
    backgroundColor: lightGrayColor,
  },
  mobileShadow: {
    boxShadow: "1px 1px 9px 0px #0003",
    padding: "0px 20px 15px 20px",
  },
  videoSection: {
    "& $howItWorks": {
      textAlign: "center",
    },
  },
  desktopStockDiv: {
    color: "green",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
  },
  desktopShippingIcon: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      color: logoColor,
    },
    "& span": {
      marginLeft: "10px",
    },
  },
  fingerTest: {
    color: dangerColor[0],
    marginLeft: 10,
  },
  desktopQtyGrid: {
    display: "flex",
    alignItems: "center",
    "& button": {
      boxShadow: "none",
      padding: 6,
      border: "1px solid gray",
      "& span": {
        padding: "0px",
      },
    },
    "& span": {
      color: "#1c313a",
      fontWeight: "500",
      padding: "10px 20px",
      fontSize: "1.3rem",
    },
  },
  desktopAddToCart: {
    fontSize: "0.9rem",
    padding: "15px 50px",
  },
  parallaxDesktop: {
    backgroundSize: "cover",
    backgroundPosition: "top center",
    height: "70vh",
    backgroundAttachment: "fixed",
  },
  mobileIncludedTitle: {
    marginTop: "10px",
    fontWeight: "500",
    textAlign: "center",
    textTransform: "uppercase",
  },
  mobileDivTitle: {
    marginTop: "40px",
    marginBottom: "20px",
  },
  qtyTitleMobile: {
    
  }
};

export default productStyle;
