import { borderGrayColor } from "assets/jss/material-kit-pro-react";
// import { lightGrayColor } from "assets/jss/material-kit-pro-react";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import { bodyTextColor } from "assets/jss/material-kit-pro-react";
import { grayColor } from "assets/jss/material-kit-pro-react";
import { secondaryDarkColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";

const dashboardStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  container: {
    ...container,
    zIndex: 1,
  },
  title: {
    ...title,
    marginBottom: 30,
    marginTop: 0,
  },
  textCenter: {
    textAlign: "center",
  },
  mainSectionDiv: {
    paddingTop: 30,
    paddingBottom: 50,
  },
  summaryCard: {
    border: "1px solid" + secondaryDarkColor,
    background: secondaryDarkColor,
    color: whiteColor,
    padding: "5px 20px",
    borderRadius: "5px",
    marginBottom: "20px",
  },
  summaryCardInnerDiv: {
    borderBottom: "1px solid" + borderGrayColor,
    paddingBottom: "10px",
    marginBottom: "15px",
    "& h4": {
      fontSize: "1.2rem",
      marginBottom: "0px",
      marginTop: "10px",
    },
    "& p": {
      marginTop: "20px",
      lineHeight: "1",
      fontSize: "1.8rem",
      fontWeight: "400",
    },
  },
  purchaseHistory: {
    textAlign: "center",
    marginTop: "50px",
  },
  valueContainer: {
    marginTop: "20px",
    border: "1px solid" + borderGrayColor,
    padding: "25px 0px",
    boxShadow: "3px 2px 4px #00000038",
    "& p": {
      fontSize: "1rem",
      fontWeight: "600",
      marginBottom: "0px",
    },
  },
  purchaseHistoryTitle: {
    marginTop: "50px",
    borderBottom: "2px solid" + grayColor[1],
    width: "fit-content",
    fontWeight: "600",
  },
  purchaseHistoryTitleContainer: {
    color: bodyTextColor,
  },
  statusPending: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",    
    "& svg": {
      marginRight: 5,
    },
  },
  borderBottomMobile: {
    borderBottom: "1px solid" + borderGrayColor,
  },
  valueContainerMobile: {
    "& h5": {
      marginBottom: "0px",
    },
    "& p": {
      color: "#9e9e9e",
    },
  },
};

export default dashboardStyle;
