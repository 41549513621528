import axios from "axios";
import { GetBaseApiEndPoint } from "./../appConfig";
const BASE_URL = GetBaseApiEndPoint();

let reqConfig = {
  headers: {
    "Content-Type": "application/json"
  }
};

export async function postRequestWithOutToken(url, data) {
  return axios
    .post(`${BASE_URL}${url}`, data, reqConfig)
    .then(response => response.data)
    .catch(error => {
      //error.response.status === 401;
      throw error;
    });
}
