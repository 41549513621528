import gql from "graphql-tag";

export const LogIn = gql`
  mutation Login($email: String, $password: String) {
    Login(email: $email, password: $password) {
      user {
        name
        email
        mobile_no
        order {
          total_amount
          shipping_address_id
          billing_address_id
          shipping_charge_value
          order_status
          orderProduct {
            amount
            quantity
            product_meta
          }
        }
      }
      token
    }
  }
`;

export const VerifyUser = gql`
  mutation verifyUser($token: String) {
    verifyUser(token: $token) {
      id
      name
      mobile_no
      verified
    }
  }
`;
