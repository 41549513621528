/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Footer = lazy(() => import("../../components/Footer/Footer"));
const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const GridItem = lazy(() => import("components/Grid/GridItem"));

import { payment } from "../../redux/actions/OrderActions";

import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: props.location.state && props.location.state.total,
      qty: props.location.state && props.location.state.qty
    };
  }

  paymentSuccess = (details, data) => {
    const { qty } = this.state;
    const { payment, client } = this.props;
    const paymentData = {
      qty,
      productId: 2,
      response: JSON.stringify(details)
    };

    payment(paymentData, client);
    this.props.history.push("/order");
  };

  render() {
    const { classes } = this.props;
    const { total } = this.state;
    return (
      <>
        <Suspense fallback={<div />}>
          <div>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />
            <div
              style={{
                minHeight: "85vh",
                backgroundColor: "white",
                paddingBottom: "5vh"
              }}
            >
              <div className={classes.container} style={{ paddingTop: "22vh" }}>
                <GridContainer justify="center">
                  <GridItem sm={11} md={6}>
                    <PayPalButton
                      amount={total}
                      options={{
                        clientId: "sb",
                        currency: "EUR"
                      }}
                      onSuccess={(details, data) =>
                        this.paymentSuccess(details, data)
                      }
                      onButtonReady={() => this.setState({ showLoading: false })}
                      catchError={(err) => console.log(err, "err")}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      payment
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withApollo(withStyles(styles)(Payment)));
