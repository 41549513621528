import {
  GET_PAYMENT_LINK_REQUEST,
  GET_PAYMENT_LINK_SUCCESS,
  GET_PAYMENT_LINK_ERROR,
  CLEAR_PAYMENT_FLAG
} from "../type/PaymentType";
import { GetPaymentLink } from "../../graphql/Mutations/PaymentMutation";

export const getPaymentLinkRequest = payload => {
  return {
    type: GET_PAYMENT_LINK_REQUEST,
    payload
  };
};

export const getPaymentLinkSuccess = payload => {
  return {
    type: GET_PAYMENT_LINK_SUCCESS,
    payload
  };
};

export const getPaymentLinkError = payload => {
  return {
    type: GET_PAYMENT_LINK_ERROR,
    payload
  };
};
export const clearPaymentFlag = () => {
  return {
    type: CLEAR_PAYMENT_FLAG
  };
};

export const getPaymentLink = (values, client) => {
  return dispatch => {
    dispatch(getPaymentLinkRequest());
    return client
      .mutate({
        variables: values,
        mutation: GetPaymentLink
      })
      .then(result => dispatch(getPaymentLinkSuccess(result)))
      .catch(error => dispatch(getPaymentLinkError(error)));
  };
};
