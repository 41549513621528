import {
  SIGN_UP_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_ERROR,
  CLEAR_SIGN_UP_FLAG
} from "../type/SignUpType";
import { SignUp } from "../../graphql/Mutations/SignUpMutation";

export const signUpRequest = payload => {
  return {
    type: SIGN_UP_REQUEST,
    payload
  };
};

export const signUpSuccess = payload => {
  return {
    type: SIGN_UP_SUCCESS,
    payload
  };
};

export const signUpError = payload => {
  return {
    type: SIGN_UP_ERROR,
    payload
  };
};

export const signUp = (values, client) => {
  return dispatch => {
    dispatch(signUpRequest());
    return client
      .mutate({
        variables: values,
        mutation: SignUp
      })
      .then(result => dispatch(signUpSuccess(result)))
      .catch(error => dispatch(signUpError(error)));
  };
};

export const signClearFlag = () => {
  return {
    type: CLEAR_SIGN_UP_FLAG
  };
};
