/*eslint-disable*/
import React, { lazy, useState, Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import Timeline from "@material-ui/icons/Timeline";
import { Tooltip } from "@material-ui/core";
import { isMobileOnly } from "react-device-detect";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const SectionTimeline = lazy(() => import("views/Timeline/Timeline.js"));

import { DashboardSummaryCard } from "constants/Data";
import { DashboardPurchaseHistory } from "constants/Data";

import presentationStyle from "assets/jss/material-kit-pro-react/views/dashboardStyles.js";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  const [openTimeline, setOpenTimeline] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const myId = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView();
    }
  }, []);

  const handleOpenTimeline = () => {
    setOpenTimeline(!openTimeline);
  };

  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div />}>
        <Header
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: "white",
          }}
        />
        <Parallax
          image={require("assets/img/img2.jpg")}
          className={classes.parallax}
        />
        <SectionTimeline open={openTimeline} handleOpen={handleOpenTimeline} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <div className={classes.mainSectionDiv}>
              <div>
                <h2 className={classes.title}>History</h2>
              </div>
              <div className={classes.sectionCard}>
                <GridContainer justify="space-between">
                  {DashboardSummaryCard.map((card, index) => (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      key={card.cardTitle + index}
                    >
                      <div className={classes.summaryCard}>
                        <div className={classes.summaryCardInnerDiv}>
                          <h4>{card.cardTitle}</h4>
                          <p>
                            {" "}
                            <TrendingFlatIcon /> {card.value}
                          </p>
                        </div>
                        <div>
                          <p> {card.cardSubTitle} </p>
                        </div>
                      </div>
                    </GridItem>
                  ))}
                </GridContainer>
              </div>
              <h3 className={classes.purchaseHistoryTitle}>Purchase History</h3>
              {!isMobileOnly ? (
                <div className={classes.purchaseHistory}>
                  <div>
                    <GridContainer
                      className={classes.purchaseHistoryTitleContainer}
                    >
                      <GridItem sm={2} md={2} lg={2}>
                        <h5>No </h5>
                      </GridItem>
                      <GridItem sm={2} md={2} lg={2}>
                        <h5>Test Kit </h5>
                      </GridItem>
                      <GridItem sm={2} md={2} lg={2}>
                        <h5>Date </h5>
                      </GridItem>
                      <GridItem sm={2} md={2} lg={2}>
                        <h5>Qty</h5>
                      </GridItem>
                      <GridItem sm={2} md={2} lg={2}>
                        <h5>Price</h5>
                      </GridItem>
                      <GridItem sm={2} md={2} lg={2}>
                        <h5>Status</h5>
                      </GridItem>
                    </GridContainer>
                    {DashboardPurchaseHistory.map((item, index) => (
                      <div className={classes.valueContainer}>
                        <GridContainer>
                          <GridItem sm={2} md={2} lg={2}>
                            <p>{item.no}</p>
                          </GridItem>
                          <GridItem sm={2} md={2} lg={2}>
                            <p>{item.name} </p>
                          </GridItem>
                          <GridItem sm={2} md={2} lg={2}>
                            <p>{item.date}</p>
                          </GridItem>
                          <GridItem sm={2} md={2} lg={2}>
                            <p>{item.qty}</p>
                          </GridItem>
                          <GridItem sm={2} md={2} lg={2}>
                            <p> &euro; {item.price} </p>
                          </GridItem>
                          <GridItem sm={2} md={2} lg={2}>
                            <p
                              className="iconMuiTable"
                              onClick={handleOpenTimeline}
                            >
                              <Tooltip title="Timeline">
                                <Timeline />
                              </Tooltip>
                            </p>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  {DashboardPurchaseHistory.map((item, index) => (
                    <div
                      className={
                        classes.valueContainer +
                        " " +
                        classes.valueContainerMobile
                      }
                    >
                      <GridContainer justify="center">
                        <GridItem
                          md={5}
                          xs={5}
                          className={classes.borderBottomMobile}
                        >
                          <p>{item.no}</p>
                        </GridItem>
                        <GridItem
                          md={5}
                          xs={5}
                          className={classes.borderBottomMobile}
                        >
                          <p
                            className="iconMuiTable"
                            onClick={handleOpenTimeline}
                          >
                            <Tooltip title="Timeline">
                              <Timeline />
                            </Tooltip>
                          </p>
                        </GridItem>
                        <GridItem md={5} xs={5}>
                          <h5>Test Kit </h5>
                          <p>{item.name} </p>
                        </GridItem>
                        <GridItem md={5} xs={5}>
                          <h5>Date </h5>
                          <p>{item.date}</p>
                        </GridItem>
                        <GridItem md={5} xs={5}>
                          <h5>Qty</h5>
                          <p>{item.qty}</p>
                        </GridItem>
                        <GridItem md={5} xs={5}>
                          <h5>Price</h5>
                          <p> &euro; {item.price} </p>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </Suspense>
    </div>
  );
}
