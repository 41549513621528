import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  CLEAR_SIGN_UP_FLAG
} from "../../redux/type/SignUpType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  signUpSuccess: false,
  isSignUpLoading: false,
  signUpData: ""
};

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      if(action.payload && action.payload.signUpReducer) {        
        return { ...action.payload.signUpReducer };
      }
      return {
        ...state
      }
    }
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signUpSuccess: false,
        isSignUpLoading: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpSuccess: true,
        isSignUpLoading: false,
        signUpData: action.payload
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUpSuccess: false,
        isSignUpLoading: false,
      };
    case CLEAR_SIGN_UP_FLAG:
      return {
        ...state,
        signUpSuccess: false,
        isSignUpLoading: false,
      };

    default:
      return state;
  }
}
