import { combineReducers } from "redux";
import contactReducer from "./reducers/ContactReducer";
import cartReducer from "./reducers/OrderCartReducer";
import signUpReducer from "./reducers/SignUpReducer";
import logInReducer from "./reducers/LoginReducer";
import promoCodeReducer from "./reducers/PromoReducer";
import paymentReducer from "./reducers/PaymentReducer";
import orderReducer from "./reducers/OrderReducer";
import selfAssessmentReducer from "./reducers/SelfAssessmentReducer";
import checkAssessmentReducer from "./reducers/CheckAssessmentReducer";

export default combineReducers({
  contactReducer,
  cartReducer,
  signUpReducer,
  logInReducer,
  promoCodeReducer,
  paymentReducer,
  orderReducer,  
  selfAssessmentReducer,
  checkAssessmentReducer,
});
