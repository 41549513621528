import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  VERIFY_USER_ERROR,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_REQUEST,
  CLEAR_LOG_IN_FLAG
} from "../type/LoginType";
import { LogIn, VerifyUser } from "../../graphql/Mutations/LogInMutation";

export const logInRequest = payload => {
  return {
    type: LOG_IN_REQUEST,
    payload
  };
};

export const logInSuccess = payload => {
  return {
    type: LOG_IN_SUCCESS,
    payload
  };
};

export const logInError = payload => {
  return {
    type: LOG_IN_ERROR,
    payload
  };
};

export const logIn = (values, client) => {
  return dispatch => {
    dispatch(logInRequest());
    return client
      .mutate({
        variables: values,
        mutation: LogIn
      })
      .then(result => dispatch(logInSuccess(result)))
      .catch(error => dispatch(logInError(error)));
  };
};

export const verifyUserRequest = payload => {
  return {
    type: VERIFY_USER_REQUEST,
    payload
  };
};

export const verifyUserSuccess = payload => {
  return {
    type: VERIFY_USER_SUCCESS,
    payload
  };
};

export const verifyUserError = payload => {
  return {
    type: VERIFY_USER_ERROR,
    payload
  };
};

export const verifyUser = (values, client) => {
  return dispatch => {
    dispatch(verifyUserRequest());
    return client
      .mutate({
        variables: values,
        mutation: VerifyUser
      })
      .then(result => dispatch(verifyUserSuccess(result)))
      .catch(error => dispatch(verifyUserError(error)));
  };
};

export const clearLoginFlag = () => {
  return {
    type: CLEAR_LOG_IN_FLAG
  };
};
