import {
  GET_PAYMENT_LINK_REQUEST,
  GET_PAYMENT_LINK_SUCCESS,
  GET_PAYMENT_LINK_ERROR,
  CLEAR_PAYMENT_FLAG
} from "../type/PaymentType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  paymentLink: "",
  getLinkSuccess: false
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.paymentReducer) {
        return { ...action.payload.paymentReducer };
      }

      return {
        ...state
      };
    case GET_PAYMENT_LINK_REQUEST:
      return {
        ...state
      };
    case GET_PAYMENT_LINK_SUCCESS:
      const {
        payload: { data }
      } = action;
      return {
        ...state,
        getLinkSuccess: true,
        paymentLink: data.GetPaymentLink.paypal_link
      };
    case GET_PAYMENT_LINK_ERROR:
      return {
        ...state
      };
    case CLEAR_PAYMENT_FLAG:
      return {
        ...state,
        getLinkSuccess: false
      };
    default:
      return {
        ...state
      };
  }
}
