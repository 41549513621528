import order from "assets/img/order-white.svg";
import receive from "assets/img/delivery.svg";
import sent from "assets/img/exchange-white.svg";
import result from "assets/img/medical-result.svg";
import help from "assets/img/hospital.svg";
import { secondaryMainColor } from "assets/jss/material-kit-pro-react";

export const MailId = "info@menergy.com";

export const AboutUSDesc = [
  {
    desc:
      "Menergy provides medically supervised hormone replacement of testosterone deficiencies. The fact that each individual is unique makes the development of an individualised hormone replacement therapy essential for best results.",
  },
  {
    desc:
      "With an individualised hormone replacement therapy and the wellbeing programme, you will experience reversal of your symptoms such a boost in stamina, mood, improve concentrations, memory and libido. All the above will improve your quality of life.",
  },
  {
    desc:
      "The in-depth home blood testing at Menergy along with regular follow ups with our clinically experienced physicians, is part of the secret in creating the most efficient pathway to an improved wellbeing.",
  },
  {
    desc:
      "Wellness is our craft and we never stop looking after your heath. We are transparent, ethical and passionate about what we do. We at Menergy, put you first and strive to provide the best holistic care.",
  },
];

export const Features = [
  {
    title: "Order Test Kit",
    image: order,
    feature: "Order your home test kit to check your baseline blood levels.",
  },
  {
    title: "Receive Test Kit",
    image: receive,
    feature: "You will receive your home test kit within 1-2 working days.",
  },
  {
    title: "Return Test Kit",
    image: sent,
    feature:
      " Simply return your home test kit to our laboratory Monday-Thursday as soon as the samples collected (return in envelope provided).",
  },
  {
    title: "Receive Results",
    image: result,
    feature:
      "Once your blood tests are processed, confidential results will be available from your secure online account.",
  },
  {
    title: "Medical Support",
    image: help,
    feature:
      " Receive your individualised treatment and wellbeing consultation from our dedicated physician by encrypted chat, phone, or video.",
  },
];

export const Options = [
  {
    title: "None",
    value: "1",
  },
  {
    title: "Mild",
    value: "2",
  },
  {
    title: "Moderate",
    value: "3",
  },
  {
    title: "Severe",
    value: "4",
  },
  {
    title: "Extremely Severe",
    value: "5",
  },
];

export const Links = [
  {
    color: "twitter",
    class: "fab fa-twitter",
  },
  {
    color: "facebook",
    class: "fab fa-facebook-square",
  },
  {
    color: "dribbble",
    class: "fab fa-dribbble",
  },
  {
    color: "google",
    class: "fab fa-google-plus-g",
  },
];

export const TeamLinks = [
  {
    color: "twitter",
    class: "fab fa-twitter",
  },
  {
    color: "facebook",
    class: "fab fa-facebook-square",
  },
  {
    color: "dribbble",
    class: "fab fa-dribbble",
  },
];

export const DashboardSummaryCard = [
  {
    cardTitle: "Total Orders",
    value: "15 orders",
    cardSubTitle: "Place another Order",
  },
  {
    cardTitle: "Current Package",
    value: "Quarterly (Basic)",
    cardSubTitle: "View Other Plans",
  },
  {
    cardTitle: "Last Test kit Result",
    value: "Negative",
    cardSubTitle: "View All Results",
  },
];

export const DashboardPurchaseHistory = [
  {
    no: "1",
    name: "Testosterone",
    date: "26/11/2020",
    qty: "1",
    price: "65",
    status: "Confirm",
  },
  {
    no: "2",
    name: "Testosterone",
    date: "24/11/2020",
    qty: "2",
    price: "130",
    status: "Shipped",
  },
  {
    no: "3",
    name: "Testosterone",
    date: "05/09/2020",
    qty: "1",
    price: "65",
    status: "Delivered",
  },
];

export const bloodTestResultColumns = [
  "Testosterone",
  "Free Testosterone",
  "Sex Hormone Binding Globulin (SHBG)",
  "PSA",
  "Luitenising Hormone (LH)",
  "Follicle Stimulating Hormone (FSH)",
  "Full Blood Count (FBC)",
  "Date",
  "Blood test",
  "Result",
  "Reference range",
  "Information",
  "Analytics",
  "Download",
];

export const barState = {
  labels: ["November", "April", "January"],
  datasets: [
    {
      label: "HAEMATOCRIT LEVEL",
      backgroundColor: secondaryMainColor,
      barPercentage: 1.0,
      data: [110, 122, 135, 90],
    },

    {
      label: "HAEMOGLOBIN",
      backgroundColor: "#006978",
      data: [140, 146, 150],
    },
  ],
};

export const lineState = {
  labels: ["November", "April", "January"],
  datasets: [
    {
      label: "HAEMATOCRIT LEVEL",
      fill: false,
      lineTension: 0.5,
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(75,192,192,1)",
      borderWidth: 2,
      data: [110, 120, 135],
    },
    {
      label: "HAEMOGLOBIN",
      fill: false,
      lineTension: 0.5,
      backgroundColor: "#006978",
      borderColor: "#006978",
      borderWidth: 2,
      data: [140, 146, 150],
    },
  ],
};

export const MedicationTablet = [
  "D-Aspartic Acid",
  "Tribulus Terrestris",
  "Vitamin D",
  "Fenugreek",
  "DHEA",
];

export const MedicationExercise = [
  "Exercise and Lift Weights",
  "Eat Protein, Fat and Carbs",
  "Minimize Stress and Cortisol Levels",
  "Get Plenty of Restful, High-Quality Sleep",
  "Avoid Estrogen-like Compounds",
];

export const InformationCardLayout = [
  {
    data: [
      {
        title: "Date",
        value: "03/11/2020",
      },
      {
        title: "Testosterone",
        value: "1.4",
      },
      {
        title: "Free Testosterone",
        value: "1.2",
      },
    ],
    isOpen: false,
    readMore: [
      {
        title: "Lutenising Hormone",
        value: "10.1",
      },
      {
        title: "Follicle Stim. Hormone",
        value: "3.1",
      },
      {
        title: "Sex Hormone Binding Globulin",
        value: "89",
      },
      {
        title: "PSA",
        value: "0.03",
      },
    ],
  },
  {
    data: [
      {
        title: "Date",
        value: "21/04/2020",
      },
      {
        title: "Testosterone",
        value: "1.8",
      },
      {
        title: "Free Testosterone",
        value: "1.4",
      },
    ],
    isOpen: false,
    readMore: [
      {
        title: "Lutenising Hormone",
        value: "12.1",
      },
      {
        title: "Follicle Stim. Hormone",
        value: "3.5",
      },
      {
        title: "Sex Hormone Binding Globulin",
        value: "91",
      },
      {
        title: "PSA",
        value: "0.03",
      },
    ],
  },
  {
    data: [
      {
        title: "Date",
        value: "11/01/2020",
      },
      {
        title: "Testosterone",
        value: "2.1",
      },
      {
        title: "Free Testosterone",
        value: "1.8",
      },
    ],
    isOpen: false,
    readMore: [
      {
        title: "Lutenising Hormone",
        value: "13.1",
      },
      {
        title: "Follicle Stim. Hormone",
        value: "3.8",
      },
      {
        title: "Sex Hormone Binding Globulin",
        value: "94",
      },
      {
        title: "PSA",
        value: "0.03",
      },
    ],
  },
];

// For Calendar

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();

export const eventsCalendar = [
  {
    title: "Video Call - 5 PM",
    allDay: true,
    start: new Date(y, m, 1),
    end: new Date(y, m, 1),
    color: "theme",
  },
  {
    title: "Video Call - 4 PM",
    start: new Date(y, m, 14),
    end: new Date(y, m, 14),
  },
  {
    title: "Video Call - 7 PM",
    start: new Date(y, m, 30),
    end: new Date(y, m, 30),
  },
];

export const ChatCardData = [
  {
    image: require("assets/img/doctors/jennifer.jpg"),
    name: "Dr. Jennifer",
    designation: "Lorem Ipsum",
  },
  {
    image: require("assets/img/doctors/christian.jpg"),
    name: "Dr. Christian",
    designation: "Lorem Ipsum",
  },
  {
    image: require("assets/img/doctors/lisa.jpg"),
    name: "Dr. Lisa",
    designation: "Lorem Ipsum",
  },
  {
    image: require("assets/img/doctors/marc.jpg"),
    name: "Dr. Marc",
    designation: "Lorem Ipsum",
  },
];

export const selfAssessmentCardData = [
  {
    score: "38",
    date: "19/11/2020",
    description:
      "You have moderate symptoms consistent with a low testosterone level",
  },
  {
    score: "32",
    date: "21/12/2020",
    description:
      "You have mild symptoms consistent with a low testosterone level",
  },
  {
    score: "30",
    date: "18/01/2021",
    description:
      "You have mild symptoms consistent with a low testosterone level",
  },
  {
    score: "29",
    date: "20/02/2021",
    description:
      "You have mild symptoms consistent with a low testosterone level",
  },
];
