/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Switch from "@material-ui/core/Switch";
import MuiPhoneNumber from "material-ui-phone-number";
import { TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Button = lazy(() => import("components/CustomButtons/Button"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const Footer = lazy(() => import("components/Footer/Footer"));

import UserImage from "assets/img/placeholder.png";

import styles from "assets/jss/material-kit-pro-react/views/profileSetting/profileSettingStyles";

class ProfileSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sms: false,
      phoneNumber: "",
      emailNotification: false,
      email: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDetailChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  goToUserProfile = () => {
    this.props.history.push("/my-profile/you");
  };

  render() {
    const { classes } = this.props;
    const { sms, phoneNumber, emailNotification, email } = this.state;
    return (
      <div>
        <Suspense fallback={<div />}>
          <div className={classes.mainWhiteBackground}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />

            <div className="orderSettings">
              <div
                className={
                  classes.container + " " + classes.profileCustomContainer
                }
              >
                <h2
                  className={
                    classes.backArrowPositionTitle + " " + classes.title
                  }
                >
                  {" "}
                  <ArrowBackIcon onClick={this.goToUserProfile} /> Order Setting{" "}
                </h2>

                <GridContainer>
                  <GridItem lg={4} md={4}>
                    <div className={classes.sectionProfileImage}>
                      <img src={UserImage} alt={"menergy-user-image"} />
                      <p>Demo User</p>
                      <p className={classes.userGmail}>demouser@gmail.com</p>
                    </div>
                  </GridItem>
                  <GridItem lg={8} md={8}>
                    <div
                      className={classes.sectionProfileDetails}
                      style={{ minHeight: 400 }}
                    >
                      <p className={classes.sectionProfileDetailsTitle}>
                        Order Setting
                      </p>
                      <GridContainer className={classes.subscriptionCustomGrid}>
                        <GridItem
                          md={8}
                          lg={6}
                          sm={8}
                          xs={10}
                          className={classes.alignCenterTitle}
                        >
                          <p className={classes.orderDetailsTitle}>
                            Get Order Notification via SMS
                          </p>
                        </GridItem>
                        <GridItem md={4} lg={4} sm={4} xs={2}>
                          <Switch
                            checked={sms}
                            onChange={this.handleChange}
                            name="sms"
                            classes={{
                              checked: classes.switchColor,
                              track: classes.switchTrackColor,
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      {sms && (
                        <>
                          <GridContainer
                            className={classes.customOrderSettingGrid}
                          >
                            <GridItem md={3} lg={3} sm={3} xs={12}>
                              <p className={classes.orderDetailsTitle}>
                                Phone Number
                              </p>
                            </GridItem>
                            <GridItem md={8} lg={6} sm={9} xs={12}>
                              <MuiPhoneNumber
                                fullWidth
                                name="phoneNumber"
                                onBlur={this.handleDetailChange}
                                onChange={this.handleDetailChange}
                                defaultCountry={"gb"}
                                value={phoneNumber}
                              />
                            </GridItem>
                          </GridContainer>
                          <p className={classes.descriptionOrderNotification}>
                            Add Phone number where you want to get orders
                            notification.
                          </p>
                        </>
                      )}
                      <GridContainer>
                        <GridItem
                          md={8}
                          lg={6}
                          sm={8}
                          xs={10}
                          className={classes.alignCenterTitle}
                        >
                          <p className={classes.orderDetailsTitle}>
                            {" "}
                            Get Order Notification via Mail
                          </p>
                        </GridItem>
                        <GridItem md={4} lg={4} sm={4} xs={2}>
                          <Switch
                            checked={emailNotification}
                            onChange={this.handleChange}
                            name="emailNotification"
                            classes={{
                              checked: classes.switchColor,
                              track: classes.switchTrackColor,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      {emailNotification && (
                        <>
                          <GridContainer
                            className={classes.customOrderSettingGrid}
                          >
                            <GridItem md={3} lg={2} sm={3} xs={12}>
                              <p className={classes.orderDetailsTitle}>Email</p>
                            </GridItem>
                            <GridItem md={8} lg={6} sm={9} xs={12}>
                              <TextField
                                label="Your Email"
                                name="email"
                                fullWidth
                                onChange={this.handleChange}
                                value={email}
                                className={classes.customEmailTextField}
                                size="small"
                              />
                            </GridItem>
                          </GridContainer>
                          <p className={classes.descriptionOrderNotification}>
                            Add email where you want to get orders notification.
                          </p>
                        </>
                      )}
                      <Button
                        color="theme"
                        size="sm"
                        style={{ marginTop: "20px" }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </div>
    );
  }
}

ProfileSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileSetting);
