import gql from "graphql-tag";

export const SelfAssessment = gql`
  mutation SelfAssessment(
    $name: String
    $email: String!   
    $mobile_no: String
  ) {
    SelfAssessment(
      name: $name
      email: $email      
      mobile_no: $mobile_no
    ) {
      uuid
      email
      name   
      patient {
        id
      }   
      message
    }
  }
`;
