/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const SectionDescription = lazy(() =>
  import("views/PresentationPage/Sections/SectionDescription.js")
);
const SectionCards = lazy(() =>
  import("views/PresentationPage/Sections/SectionCards.js")
);
const SectionFreeDemo = lazy(() =>
  import("views/PresentationPage/Sections/SectionFreeDemo.js")
);
const SectionFeatures = lazy(() =>
  import("views/SectionsPage/Sections/SectionFeatures")
);
const SectionTestimonials = lazy(() =>
  import("views/SectionsPage/Sections/SectionTestimonials")
);
const PackageTabs = lazy(() => import("./Sections/Tabs"));

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const myId = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView();
    }
  });
  const classes = useStyles();
  return (
    <Suspense fallback={<div></div>}>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{
            background: "#2B373B",
            marginBottom: "40px",
            left: "40px",
            right: "40px",
            borderRadius: "50px",
            alignItems: "center",
            width: "auto",
          }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "16px",
            marginRight: "50px",
          }}
          expires={150}
          
        >
          <p
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              marginBottom: "0px",
            }}
          >
            This website uses cookies
          </p>
          <p style={{ textAlign: "center" }}>
            We inform you that this site uses own, technical and third parties
            cookies to make sure our web page is user-friendly and to guarantee
            a high functionality of the webpage. By continuing to browse this
            website, you declare to accept the use of cookies.
          </p>
        </CookieConsent>
        <Header
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: "white",
          }}
        />
        <Parallax
          image={require("assets/img/img2.jpg")}
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionDescription />
          <SectionCards />
          <div className="cd-section" style={{ padding: "70px 0" }}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={
                    classes.mlAuto +
                    " " +
                    classes.mrAuto +
                    " " +
                    classes.textCenter
                  }
                >
                  <h2 className={classes.title + " " + classes.packageTitle}>
                    Pick the best package for you
                  </h2>

                  <PackageTabs />
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <SectionFeatures />
          <SectionTestimonials />
          <div
            id="ContactUs"
            style={{ marginTop: "-120px", paddingTop: "120px" }}
          >
            <SectionFreeDemo />
          </div>
          <Footer />
        </div>
      </div>
    </Suspense>
  );
}
