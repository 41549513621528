import gql from "graphql-tag";

export const Cart = gql`
  query cart($uuid: String) {
    cart(uuid: $uuid) {
      uuid
      product_id
      quantity
    }
  }
`;
