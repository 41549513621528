/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Header = lazy(() => import("components/Header/Header.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Faq() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <>
      <Suspense fallback={<div />}>
        <div>
          <Header
            brand="Material Kit PRO React"
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 300,
              color: "white"
            }}
          />
          <Parallax
            image={require("assets/img/img1.jpg")}
            className={classes.parallax}
          />

          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              <div
                className={classNames(classes.aboutDescription, classes.textCenter)}
              >
                <h2 className={classes.title}> Transactions FAQ </h2>
                <GridContainer className={classes.gridContent}>
                  <GridItem
                    md={10}
                    sm={8}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                  >
                    <p className={classes.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id est laborum
                </p>
                    <p className={classes.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id est laborum
                </p>
                    <p className={classes.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id est laborum
                </p>
                    <p className={classes.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id est laborum
                </p>
                    <p className={classes.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id est laborum
                </p>
                  </GridItem>
                </GridContainer>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </Suspense>
    </>
  );
}
