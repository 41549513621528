import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class SectionYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumberPDF: 1,
      numPages: null,
    };
  }

  goToPrevPagePdf = () =>
    this.setState({
      pageNumberPDF: this.state.pageNumberPDF - 1,
    });

  goToNextPagePdf = () =>
    this.setState({
      pageNumberPDF: this.state.pageNumberPDF + 1,
    });

  render() {
    const { classes } = this.props;
    const { pageNumberPDF } = this.state;
    return (
      <div>
        <Header
          color="white"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <div className={classes.main}>
          <div
            className={classes.container + " " + classes.resultDetailsContainer}            
          >
            <h2 className={classes.title}>
                Result Details
            </h2>
            <Document
              file="/report1.pdf"
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumberPDF} />
            </Document>
            <Button
              color="theme"
              aria-label="Previous"
              type="button"
              onClick={this.goToPrevPagePdf}
            >
              <i className="fas fa-chevron-left" />
            </Button>
            <Button
              color="theme"
              aria-label="Next"
              type="button"
              onClick={this.goToNextPagePdf}
            >
              <i className="fas fa-chevron-right" />
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(SectionYou);
