/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import EventIcon from "@material-ui/icons/Event";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Button = lazy(() => import("components/CustomButtons/Button"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const Footer = lazy(() => import("components/Footer/Footer"));
const Dialog = lazy(() => import("components/Dialog/Dialog"));

import UserImage from "assets/img/placeholder.png";

import styles from "assets/jss/material-kit-pro-react/views/profileSetting/profileSettingStyles";

class ProfileSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      userName: "",
      email: "",
      phoneNumber: "",
      birthDate: new Date(),
      gender: "Male",
      viewImageOpen: false,
      changeImageOpen: false,
      confirmPassword: "",
      newPassword: "",
      oldPassword: "",
      showNewPasswordError: false,
      showConfirmPasswordError: false,
      oldPassWordVisibility: false,
      newPassWordVisibility: false,
      confirmNewPassWordVisibility: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChangeGender = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  viewImage = () => {
    const { viewImageOpen } = this.state;
    this.setState({
      viewImageOpen: !viewImageOpen,
    });
  };

  changeImage = () => {
    const { changeImageOpen } = this.state;
    this.setState({
      changeImageOpen: !changeImageOpen,
    });
  };

  passwordVisibility = (stateKey, value) => {
    this.setState({
      [stateKey]: value,
    });
  };

  handleDateChange = (birthDate) => {
    this.setState({ birthDate });
  };

  goToUserProfile = () => {
    this.props.history.push("/my-profile/you");
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      fullName,
      phoneNumber,
      birthDate,
      gender,
      viewImageOpen,
      confirmPassword,
      newPassword,
      oldPassword,
      showNewPasswordError,
      showConfirmPasswordError,
      changeImageOpen,
      oldPassWordVisibility,
      newPassWordVisibility,
      confirmNewPassWordVisibility,
    } = this.state;
    return (
      <div>
        <Suspense fallback={<div />}>
          <div className={classes.mainWhiteBackground}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />
            <Dialog
              open={viewImageOpen}
              handleClose={this.viewImage}
              children={
                <img src={UserImage} className={classes.dialogProfileImage} />
              }
            />
            <Dialog
              open={changeImageOpen}
              handleClose={this.changeImage}
              children={
                <div>
                  <CancelIcon
                    className="close--icon--profile--image"
                    onClick={this.changeImage}
                  />
                  <form className="profile-upload-form">
                    <label htmlFor="fileToUpload">
                      <div
                        className="profile-pic"
                        style={{
                          backgroundImage: `url(${UserImage})`,
                        }}
                      >
                        <div className="hover-image">
                          <AddAPhotoIcon />
                          <p>Change Image</p>
                        </div>
                      </div>
                    </label>
                    <input type="File" name="fileToUpload" id="fileToUpload" />
                  </form>
                  <div className="buttons-section">
                    <Button color="primary" onClick={this.changeImage}>
                      Cancel
                    </Button>
                    <Button color="theme" onClick={this.changeImage}>
                      Submit
                    </Button>
                  </div>
                </div>
              }
            />
            <div>
              <div
                className={
                  classes.container + " " + classes.profileCustomContainer
                }
              >
                <h2
                  className={
                    classes.backArrowPositionTitle + " " + classes.title
                  }
                >
                  {" "}
                  <ArrowBackIcon onClick={this.goToUserProfile} /> Profile
                  Setting{" "}
                </h2>

                <GridContainer>
                  <GridItem lg={4} md={4}>
                    <div className={classes.sectionProfileImage}>
                      <form className="profile-upload-form-custom">
                        <label htmlFor="fileToUpload">
                          <div
                            className="profile-pic"
                            style={{
                              backgroundImage: `url(${UserImage})`,
                            }}
                          >
                            <div className="hover-image">
                              <AddAPhotoIcon />
                              <p>Change Image</p>
                            </div>
                          </div>
                        </label>
                        <input
                          type="File"
                          name="fileToUpload"
                          id="fileToUpload"
                        />
                      </form>
                      <p>Demo User</p>
                      <div className={classes.sectionImageButton}>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={this.viewImage}
                        >
                          View image
                        </Button>
                        <Button
                          color="theme"
                          size="sm"
                          onClick={this.changeImage}
                        >
                          Change image
                        </Button>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem lg={8} md={8}>
                    <div className={classes.sectionProfileDetails}>
                      <p className={classes.sectionProfileDetailsTitle}>
                        Personal Detail
                      </p>
                      <Formik
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email("Email is invalid")
                            .required("Email is required"),
                          fullName: Yup.string().required(
                            "Full Name is required"
                          ),
                          phoneNumber: Yup.string().required(
                            "Phone Number is required"
                          ),
                          birthDate: Yup.string().required(
                            "Birth Date is required"
                          ),
                        })}
                        initialValues={{
                          email,
                          fullName,
                          phoneNumber,
                          birthDate,
                        }}
                        onSubmit={(values) => {
                          console.log(values);
                        }}
                      >
                        {({
                          errors,
                          values,
                          touched,
                          handleSubmit,
                          handleChange,
                        }) => (
                          <form className={classes.customFormProfile}>
                            <TextField
                              label="Your Name"
                              name="fullName"
                              fullWidth
                              margin="normal"
                              value={values.fullName}
                              onChange={handleChange}
                            />
                            {errors.fullName && touched.fullName && (
                              <FormHelperText error>
                                {errors.fullName}
                              </FormHelperText>
                            )}
                            <TextField
                              label="Your Email"
                              name="email"
                              fullWidth
                              onChange={handleChange}
                              value={values.email}
                              margin="normal"
                            />
                            {errors.email && touched.email && (
                              <FormHelperText error>
                                {errors.email}
                              </FormHelperText>
                            )}

                            <MuiPhoneNumber
                              fullWidth
                              name="phoneNumber"
                              onBlur={handleChange}
                              onChange={handleChange}
                              defaultCountry={"gb"}
                              value={values.phoneNumber}
                              margin="normal"
                              inputClass={classes.phoneNumberField}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText error>
                                {errors.phoneNumber}
                              </FormHelperText>
                            )}

                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DatePicker
                                fullWidth
                                value={birthDate}
                                margin="normal"
                                format="MM/DD/YYYY"
                                onChange={this.handleDateChange}
                                label="Enter Your Birth Date"
                                disableFuture
                                autoOk
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </MuiPickersUtilsProvider>

                            <FormControl
                              component="fieldset"
                              className={classes.customFormControl}
                            >
                              <RadioGroup
                                aria-label="gender"
                                name="gender"
                                value={gender}
                                onChange={this.handleChangeGender}
                                className={classes.customRadioGroup}
                              >
                                <FormControlLabel
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                              </RadioGroup>
                            </FormControl>

                            <Button
                              color="theme"
                              onClick={handleSubmit}
                              size="sm"
                              className={classes.submitButton}
                            >
                              Submit
                            </Button>
                          </form>
                        )}
                      </Formik>

                      <div className={classes.changePasswordSection}>
                        <p className={classes.sectionProfileDetailsTitle}>
                          Change Password
                        </p>
                        <Formik
                          enableReinitialize={true}
                          validationSchema={Yup.object().shape({
                            oldPassword: Yup.string().required(
                              "Old Password is required"
                            ),
                            newPassword: Yup.string().required(
                              "New Password is required"
                            ),
                            confirmPassword: Yup.string().required(
                              "Confirm Password is required"
                            ),
                          })}
                          initialValues={{
                            confirmPassword,
                            newPassword,
                            oldPassword,
                          }}
                          onSubmit={(values) => {
                            if (values.newPassword !== values.confirmPassword) {
                              this.setState({
                                showConfirmPasswordError: true,
                              });
                            }
                            if (values.oldPassword === values.newPassword) {
                              this.setState({
                                showNewPasswordError: true,
                              });
                            }
                          }}
                        >
                          {({
                            errors,
                            values,
                            touched,
                            handleSubmit,
                            handleChange,
                          }) => (
                            <form className={classes.customFormProfile}>
                              <GridContainer>
                                <GridItem lg={4} md={4} sm={12} xs={12}>
                                  <TextField
                                    label="Old Password"
                                    name="oldPassword"
                                    fullWidth
                                    margin="normal"
                                    value={values.oldPassword}
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        showConfirmPasswordError: false,
                                        showNewPasswordError: false,
                                      });
                                    }}
                                    type={
                                      oldPassWordVisibility
                                        ? "text"
                                        : "password"
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {oldPassWordVisibility ? (
                                            <IconButton
                                              onClick={() =>
                                                this.passwordVisibility(
                                                  "oldPassWordVisibility",
                                                  false
                                                )
                                              }
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              onClick={() =>
                                                this.passwordVisibility(
                                                  "oldPassWordVisibility",
                                                  true
                                                )
                                              }
                                            >
                                              <VisibilityOffIcon />
                                            </IconButton>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {errors.oldPassword &&
                                    touched.oldPassword && (
                                      <FormHelperText error>
                                        {errors.oldPassword}
                                      </FormHelperText>
                                    )}
                                </GridItem>
                                <GridItem lg={4} md={4} sm={12} xs={12}>
                                  <TextField
                                    label="New Password"
                                    name="newPassword"
                                    fullWidth
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        showConfirmPasswordError: false,
                                        showNewPasswordError: false,
                                      });
                                    }}
                                    value={values.newPassword}
                                    margin="normal"
                                    type={
                                      newPassWordVisibility
                                        ? "text"
                                        : "password"
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {newPassWordVisibility ? (
                                            <IconButton
                                              onClick={() =>
                                                this.passwordVisibility(
                                                  "newPassWordVisibility",
                                                  false
                                                )
                                              }
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              onClick={() =>
                                                this.passwordVisibility(
                                                  "newPassWordVisibility",
                                                  true
                                                )
                                              }
                                            >
                                              <VisibilityOffIcon />
                                            </IconButton>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {errors.newPassword &&
                                    touched.newPassword && (
                                      <FormHelperText error>
                                        {errors.newPassword}
                                      </FormHelperText>
                                    )}
                                </GridItem>
                                <GridItem lg={4} md={4} sm={12} xs={12}>
                                  <TextField
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    fullWidth
                                    margin="normal"
                                    value={values.confirmPassword}
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        showConfirmPasswordError: false,
                                        showNewPasswordError: false,
                                      });
                                    }}
                                    type={
                                      confirmNewPassWordVisibility
                                        ? "text"
                                        : "password"
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {confirmNewPassWordVisibility ? (
                                            <IconButton
                                              onClick={() =>
                                                this.passwordVisibility(
                                                  "confirmNewPassWordVisibility",
                                                  false
                                                )
                                              }
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              onClick={() =>
                                                this.passwordVisibility(
                                                  "confirmNewPassWordVisibility",
                                                  true
                                                )
                                              }
                                            >
                                              <VisibilityOffIcon />
                                            </IconButton>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {errors.confirmPassword &&
                                    touched.confirmPassword && (
                                      <FormHelperText error>
                                        {errors.confirmPassword}
                                      </FormHelperText>
                                    )}
                                </GridItem>
                              </GridContainer>
                              {showConfirmPasswordError && (
                                <FormHelperText error>
                                  New Password and Confirm Password should be
                                  same
                                </FormHelperText>
                              )}
                              {showNewPasswordError && (
                                <FormHelperText error>
                                  Old Password and New Password can't be same
                                </FormHelperText>
                              )}
                              <Button
                                color="theme"
                                onClick={handleSubmit}
                                size="sm"
                                className={classes.submitButton}
                              >
                                Change Password
                              </Button>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </div>
    );
  }
}

ProfileSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileSetting);
