/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import ImageGallery from "react-image-gallery";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Shipping from "@material-ui/icons/LocalShipping";
import Check from "@material-ui/icons/Check";
import { BrowserView, MobileView } from "react-device-detect";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Accordion = lazy(() => import("components/Accordion/Accordion.js"));
const InfoArea = lazy(() => import("components/InfoArea/InfoArea.js"));
const Footer = lazy(() => import("components/Footer/Footer"));
const SectionVideo = lazy(() => import("./SectionVideo"));

import {
  createCart,
  clearCartFlag,
} from "../../redux/actions/OrderCartActions";

import product1 from "assets/img/testkit1.jpg";
import product2 from "assets/img/testkit2.jpg";
import product3 from "assets/img/testkit1.jpg";
import product4 from "assets/img/testkit3.jpg";
import bgimg from "assets/img/web-img.png";
import mbimg from "assets/img/mobile-img.png";
import test from "assets/img/dropper.svg";
import sent from "assets/img/exchange.svg";
import result from "assets/img/medical-history.svg";
import order from "assets/img/order.svg";
import receive from "assets/img/box.svg";

import styles from "assets/jss/material-kit-pro-react/views/productStyle.js";

const images = [
  {
    original: product3,
    thumbnail: product3,
  },
  {
    original: product4,
    thumbnail: product4,
  },
  {
    original: product1,
    thumbnail: product1,
  },
  {
    original: product2,
    thumbnail: product2,
  },
];

class TestKitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      subTotal: 65,
      loggedIn: localStorage.getItem("LoggedIn"),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { addCartData, success, clearCartFlag } = props;
    if (success) {
      clearCartFlag();
      props.history.push("cart", { cartData: addCartData });
    }
    return null;
  }

  addQty = () => {
    const { qty } = this.state;
    this.setState({
      qty: qty + 1,
      subTotal: (qty + 1) * 65,
    });
  };

  decreaseQty = () => {
    const { qty } = this.state;
    if (qty > 1) {
      this.setState({
        qty: qty - 1,
        subTotal: (qty - 1) * 65,
      });
    }
  };

  addToCart = () => {
    const { createCart, client } = this.props;
    const { qty, loggedIn } = this.state;

    const addToCartData = {
      product_id: 1,
      quantity: qty,
    };
    if (loggedIn) {
      createCart(addToCartData, client);
    } else {
      this.props.history.push("/sign-in", {
        fromCartIn: true,
        cartData: addToCartData,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { qty, subTotal } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <div className={classes.productPage} style={{ minHeight: "95vh" }}>
            <Header
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="white"
              changeColorOnScroll={{
                height: 100,
                color: "white",
              }}
            />
            <div className={classes.section} style={{ padding: 0 }}>
              <div
                className={classNames(classes.main, classes.mainRaised)}
                style={{ paddingBottom: 0 }}
              >
                <div className={classes.container}>
                  <BrowserView>
                    <GridContainer
                      justify="center"
                      className={classes.mainGrid}
                    >
                      <GridItem md={6} sm={12}>
                        <ImageGallery
                          showFullscreenButton={false}
                          autoPlay
                          showPlayButton={false}
                          startIndex={0}
                          items={images}
                          thumbnailPosition={"left"}
                          showNav={false}
                        />
                      </GridItem>
                      <GridItem md={6} sm={10} className={classes.divGrid}>
                        <div>
                          <div className={classes.divTitle + " " + classes.mb0}>
                            <h3 className={classes.title}>Test Kit</h3>
                            <p
                              className={classes.title}
                              style={{ fontSize: "1.3rem" }}
                            >
                              &euro; 65
                            </p>
                          </div>

                          <div className={classes.shipping}>
                            <div className={classes.desktopStockDiv}>
                              {" "}
                              <span>In Stock</span>{" "}
                            </div>
                            <div className={classes.desktopShippingIcon}>
                              <Shipping /> <span>Free Shipping</span>
                            </div>
                          </div>
                          <ul className={classes.ulDesc}>
                            <li>
                              {" "}
                              <Check className={classes.iconCheck} /> Collection
                              Method{" "}
                              <span className={classes.fingerTest}>
                                {" "}
                                Finger Prick{" "}
                              </span>{" "}
                            </li>
                            <li>
                              {" "}
                              <Check className={classes.iconCheck} /> The test
                              must be taken fasted, before 9 a.m and returned on
                              the same day.
                            </li>
                          </ul>
                        </div>

                        <div>
                          <Accordion
                            activeColor="theme"
                            collapses={[
                              {
                                title: "Test Kit Description",
                                content: (
                                  <p>
                                    Test Kit to measure the amount of
                                    testosterone present in your blood and check
                                    if you are suffering from testosterone
                                    deficiencies or not.
                                  </p>
                                ),
                              },
                              {
                                title: "What does this Test Kit includes?",
                                content: (
                                  <ul>
                                    <li>Detailed directions to guide you</li>
                                    <li>Tube for Transporting your sample</li>
                                    <li>Biohazard Bag</li>
                                    <li>Blood collection Bag</li>
                                    <li>Return Envelope</li>
                                  </ul>
                                ),
                              },
                              {
                                title: " How To Use Test Kit?",
                                content: (
                                  <ul>
                                    <li>
                                      The test must be taken fasted, before 9
                                      a.m and returned on the same day.
                                    </li>
                                  </ul>
                                ),
                              },
                            ]}
                          />
                        </div>

                        <GridContainer
                          className={classes.pickSize}
                          justify="space-between"
                        >
                          <GridItem
                            md={4}
                            sm={6}
                            xs={12}
                            className={classes.desktopQtyGrid}
                          >
                            <Button
                              color="transparent"
                              size="sm"
                              onClick={this.decreaseQty}
                            >
                              <Remove className={classes.size} />
                            </Button>
                            <span> {qty} </span>
                            <Button
                              color="transparent"
                              size="sm"
                              onClick={this.addQty}
                            >
                              <Add className={classes.size} />
                            </Button>
                          </GridItem>

                          <GridItem md={5} sm={12} xs={12}>
                            <Button
                              color="theme"
                              onClick={this.addToCart}
                              className={classes.desktopAddToCart}
                            >
                              <ShoppingCart /> &nbsp; Add to Cart
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </BrowserView>

                  <MobileView>
                    <GridContainer justify="center">
                      <GridItem xs={12}>
                        <div className={classes.divTitle}>
                          <h3 className={classes.title}>Test Kit</h3>
                          <p
                            className={classes.title}
                            style={{ fontSize: "1.3rem" }}
                          >
                            &euro; 65
                          </p>
                        </div>
                      </GridItem>
                      <GridItem xs={12} style={{ marginBottom: "50px" }}>
                        <ImageGallery
                          showFullscreenButton={false}
                          showPlayButton={false}
                          startIndex={0}
                          items={images}
                          thumbnailPosition={"bottom"}
                        />
                        <div className={classes.mobileShadow}>
                          <div
                            className={
                              classes.divTitle + " " + classes.mobileDivTitle
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              className={classes.qtyTitleMobile}
                            >
                              <h6 style={{ fontWeight: "700" }}> QTY </h6>
                              <Button
                                color="transparent"
                                size="sm"
                                onClick={this.decreaseQty}
                                style={{ boxShadow: "none", padding: "10px" }}
                              >
                                <Remove />
                              </Button>
                              <span
                                style={{
                                  color: "#1c313a",
                                  fontWeight: "500",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {" "}
                                {qty}{" "}
                              </span>
                              <Button
                                color="transparent"
                                size="sm"
                                onClick={this.addQty}
                                style={{ boxShadow: "none", padding: "10px" }}
                              >
                                <Add />
                              </Button>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <h6
                                style={{ marginRight: 15, fontWeight: "700" }}
                              >
                                {" "}
                                Total{" "}
                              </h6>
                              <p
                                style={{
                                  color: "#1c313a",
                                  fontWeight: "500",
                                  fontSize: "1.2rem",
                                  marginTop: "10px",
                                }}
                              >
                                {" "}
                                &euro; {subTotal}{" "}
                              </p>
                            </div>
                          </div>
                          <div>
                            <Button
                              fullWidth
                              color="theme"
                              onClick={this.addToCart}
                            >
                              <ShoppingCart /> &nbsp; Add to Cart
                            </Button>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12}>
                        <Accordion
                          active={0}
                          activeColor="theme"
                          collapses={[
                            {
                              title: "Test Kit Description",
                              content: (
                                <p>
                                  Test Kit to measure the amount of testosterone
                                  present in your blood and check if you are
                                  suffering from testosterone deficiencies or
                                  not.
                                </p>
                              ),
                            },
                            {
                              title: "What does this Test Kit includes?",
                              content: (
                                <ul>
                                  <li>Detailed directions to guide you</li>
                                  <li>Tube for Transporting your sample</li>
                                  <li>Biohazard Bag</li>
                                  <li>Blood collection Bag</li>
                                  <li>Return Envelope</li>
                                </ul>
                              ),
                            },
                            {
                              title: " How To Use Test Kit?",
                              content: (
                                <ul>
                                  <li>
                                    The test must be taken fasted, before 9 a.m
                                    and returned on the same day.
                                  </li>
                                </ul>
                              ),
                            },
                          ]}
                        />
                      </GridItem>
                    </GridContainer>
                  </MobileView>
                </div>
              </div>

              <div
                className={classes.container}
                style={{ marginBottom: "80px" }}
              >
                <div className={classes.videoSection}>
                  <h2 className={classes.howItWorks}>
                    {" "}
                    How to Collect Blood Sample{" "}
                  </h2>
                  <SectionVideo />
                </div>
              </div>

              <BrowserView>
                <div
                  style={{
                    backgroundImage: "url(" + bgimg + ")",
                  }}
                  className={classes.parallaxDesktop}
                ></div>
              </BrowserView>

              <MobileView>
                <h2 className={classes.mobileIncludedTitle}>
                  {" "}
                  What's Included{" "}
                </h2>
                <img
                  src={mbimg}
                  style={{ width: "100%" }}
                  className={classes.mobileImage}
                />
                <div>
                  <ul className={classes.ulDesc + " " + classes.inclusion}>
                    <li>
                      {" "}
                      <Check className={classes.iconCheck} /> Tube for
                      transporting your sample{" "}
                    </li>
                    <li>
                      {" "}
                      <Check className={classes.iconCheck} /> Pre-paid shipping
                      both ways{" "}
                    </li>
                    <li>
                      {" "}
                      <Check className={classes.iconCheck} /> Detailed
                      directions to guide you{" "}
                    </li>
                    <li>
                      {" "}
                      <Check className={classes.iconCheck} /> Help along the way
                      if you need it{" "}
                    </li>
                    <li>
                      {" "}
                      <Check className={classes.iconCheck} /> Biohazard bag for
                      returning your sample{" "}
                    </li>
                  </ul>
                </div>
              </MobileView>

              <div className={classes.container} style={{ paddingBottom: 50 }}>
                <div
                  className={classNames(classes.features, classes.textCenter)}
                >
                  <h2 className={classes.howItWorks}> How it Works </h2>
                  <GridContainer justify="center">
                    <GridItem md={5} sm={6} xs={12}>
                      <InfoArea
                        title="Order the test kit"
                        icon={order}
                        vertical
                        test={true}
                      />
                    </GridItem>
                    <GridItem md={5} sm={6} xs={12}>
                      <InfoArea
                        title="Once you order, the test materials are delivered to your doorstep"
                        icon={receive}
                        vertical
                        test={true}
                      />
                    </GridItem>
                    <GridItem md={5} sm={6} xs={12}>
                      <InfoArea
                        title="Complete the simple blood sample collection"
                        icon={test}
                        vertical
                        test={true}
                      />
                    </GridItem>
                    <GridItem md={5} sm={6} xs={12}>
                      <InfoArea
                        title="After you complete the test return kit to laboratory"
                        icon={sent}
                        vertical
                        test={true}
                      />
                    </GridItem>
                    <GridItem md={5} sm={6} xs={12}>
                      <InfoArea
                        title="you’ll receive your result within days"
                        icon={result}
                        vertical
                        test={true}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              </div>

              <div style={{ backgroundColor: "white", padding: "50px 0" }}>
                <GridContainer justify="center">
                  <GridItem xs={10} sm={10} md={6}>
                    <h2
                      style={{
                        marginTop: "10px",
                        fontWeight: "500",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {" "}
                      Dive Deeper{" "}
                    </h2>
                    <Accordion
                      style={{ color: "white" }}
                      activeColor="theme"
                      collapses={[
                        {
                          title: "What is Testosterone Deficiency?",
                          content: (
                            <p>
                              Testosterone is commonly thought of as the “male”
                              hormone, and certainly male characteristics are
                              the result of testosterone production in the body.
                              However, women also produce testosterone and need
                              healthy levels of it for hormone balance. From
                              libido to facial hair to muscle mass to voice
                              depth to energy levels and more, this hormone is
                              vital for men, and a lower than normal level of
                              testosterone or “Low T,” may cause troubling
                              health challenges.
                            </p>
                          ),
                        },
                        {
                          title: "Who should take this testosterone test?",
                          content: (
                            <p>
                              Both men and women can check free testosterone
                              levels with this at home testosterone test.
                            </p>
                          ),
                        },
                        {
                          title: "What will this result shows?",
                          content: (
                            <p>
                              Your results will provide detailed information on
                              whether your testosterone level is normal, low, or
                              high compared to reference ranges in your age
                              group.
                            </p>
                          ),
                        },
                        {
                          title: "Common signs of lowered testosterone",
                          content: (
                            <ul>
                              <li>Depression</li>
                              <li>Lowered Self-confidence</li>
                              <li>Bad sleep</li>
                              <li>Low sex drive</li>
                              <li>Osteoporosis/brittle bones/bone breaks</li>
                            </ul>
                          ),
                        },
                        {
                          title: "What will this result shows?",
                          content: (
                            <p>
                              Your results will provide detailed information on
                              whether your testosterone level is normal, low, or
                              high compared to reference ranges in your age
                              group.
                            </p>
                          ),
                        },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </div>
              <Footer />
            </div>
          </div>
        </Suspense>
      </>
    );
  }
}

TestKitPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  addCartData: state.cartReducer && state.cartReducer.addCartData,
  success: state.cartReducer && state.cartReducer.addCartSuccess,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createCart,
      clearCartFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(TestKitPage)));
