import { secondaryMainColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const ProfileSetting = {
  container,
  title,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  profileCustomContainer: {
    paddingTop: "15vh",
    minHeight: "80vh",
  },
  mainWhiteBackground: {
    backgroundColor: whiteColor,
  },
  sectionProfileImage: {
    backgroundColor: "#efefef",
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "50px",
    "& img": {
      height: "150px",
      borderRadius: "50%",
    },
    "& p": {
      fontSize: "1.2rem",
      fontWeight: "500",
      marginTop: "20px",
    },
    "@media (max-width: 768px)": {
      minHeight: "300px",
      marginBottom: "20px",
    },
  },
  sectionProfileDetails: {
    backgroundColor: "#efefef",
    minHeight: "500px",
    padding: "40px 50px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "80px",
    flexDirection: "column",
    justifyContent: "center",
    "& .$MuiGrid-container": {
      width: "100%",
    },
    "@media (max-width: 768px)": {
      padding: "20px",
    }
  },
  sectionProfileDetailsTitle: {
    fontSize: "1.2rem",
    fontWeight: "600",
  },
  sectionImageButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      margin: "0px 5px",
    },
  },
  submitButton: {
    marginTop: "15px",
  },
  phoneNumberField: {
    marginTop: "25px",
    marginBottom: "8px",
  },
  customRadioGroup: {
    flexDirection: "row",
    marginTop: "30px",
    "& label": {
      color: "#757575"
    }
  },
  customFormControl: {
    display: "block",
    "& .$MuiRadio-colorSecondary.Mui-checked": {
      color: secondaryMainColor,
    },
  },
  dialogProfileImage: {
    height: "350px",
    width: "auto",
  },
  customFormProfile: {
    width: "100%",
  },
  changePasswordSection: {
    marginTop: "20px",
    width: "100%",
  },
  userGmail: {
    marginTop: "0px !important",
  },
  sectionProfileDetailsSubTitle: {
    marginTop: "50px",
  },
  subscriptionCustomGrid: {
    marginTop: "20px",
  },
  subscriptionDetailsTitle: {
    fontSize: "1rem",
    fontWeight: "500",
  },
  subscriptionDetailsValue: {
    fontSize: "1rem",
    fontWeight: "500",
    color: "#757575",
  },
  customPastGridContainer: {
    "& .$MuiExpansionPanelSummary-root": {
      padding: "5px 5px 5px 0px",
      borderBottom: "none",
    },
    "& .$MuiExpansionPanelDetails-root": {
      borderTop: "1px solid #ddd",
      padding: "20px 0px",
    },
  },
  switchColor: {
    color: secondaryMainColor + "!important",
  },
  switchTrackColor: {
    backgroundColor: secondaryMainColor + "!important",
  },
  orderCustomGrid: {
    marginTop: "5px",
  },
  descriptionOrderNotification: {
    marginBottom: "40px",
    fontWeight: "400"
  },
  orderDetailsTitle: {
    marginBottom: "0px",
    fontWeight: 500,
    fontSize: "15px",
  },
  customOrderSettingGrid: {
    alignItems: "center",
    marginBottom: "5px",
    "@media (max-width: 768px)": {
      marginTop: "20px"
    }
  },
  alignCenterTitle: {
    display: "flex",
    alignItems: "center",
  },
  customEmailTextField: {
    "& input": {
      marginTop: 5,
    },
    "& .$MuiInputLabel-formControl": {
      transform: "translate(0, 10px) scale(1)",
    }
  },
  backArrowPositionTitle: {
    position: "relative",
    "& svg": {
      position: "absolute",
      left: "0",
      top: "20px",
      height: "2rem",
      width: "2rem",
      cursor: "pointer"
    }
  }
};

export default ProfileSetting;
