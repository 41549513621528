import {
  SELF_ASSESSMENT_REQUEST,
  SELF_ASSESSMENT_SUCCESS,
  SELF_ASSESSMENT_ERROR,
  CLEAR_SELF_ASSESSMENT_FLAG
} from "../../redux/type/SelfAssessmentType";
// import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  selfAssessmentSuccess: false,
  selfAssessmentError: false,
  selfAssessmentData: "",  
};

export default function selfAssessmentReducer(state = initialState, action) {
  switch (action.type) {
    // case REHYDRATE: {
    //   return { ...action.payload.selfAssessmentReducer };
    // }
    case SELF_ASSESSMENT_REQUEST:
      return {
        ...state,
        selfAssessmentSuccess: false,
        selfAssessmentError: false
      };
    case SELF_ASSESSMENT_SUCCESS:
      const {
        payload: { data }
      } = action;
      localStorage.setItem("id", data.SelfAssessment.patient.id)
      return {
        ...state,
        selfAssessmentSuccess: true,
        selfAssessmentData: data.SelfAssessment
      };
    case SELF_ASSESSMENT_ERROR:
      return {
        ...state,
        selfAssessmentSuccess: false,
        selfAssessmentError: true,
        selfAssessmentData: action.payload.message

      };
    case CLEAR_SELF_ASSESSMENT_FLAG:
      return {
        ...state,
        selfAssessmentSuccess: false,
        selfAssessmentError: false,
      };

    default:
      return state;
  }
}
