import gql from "graphql-tag";

export const SignUp = gql`
  mutation SignUp(
    $name: String
    $email: String
    $password: String!
    $password_confirm: String!
    $mobile_no: String
  ) {
    SignUp(
      name: $name
      email: $email
      password: $password
      password_confirm: $password_confirm
      mobile_no: $mobile_no
    ) {
      uuid
      email
      name
      token
      verified
      message
    }
  }
`;
