/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Delete from "@material-ui/icons/Delete";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import Shipping from "@material-ui/icons/LocalShipping";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { BrowserView, MobileView } from "react-device-detect";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";

const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const Card = lazy(() => import("components/Card/Card"));
const Snackbar = lazy(() => import("components/Snackbar/SnackbarContent"));
const CardBody = lazy(() => import("components/Card/CardBody"));
const CardFooter = lazy(() => import("components/Card/CardFooter"));
const Footer = lazy(() => import("components/Footer/Footer"));

import img from "../../assets/img/testkit5.jpg";
import {
  getCartDetails,
  updateCartDetails,
  deleteCartDetails,
  clearCartFlag,
} from "../../redux/actions/OrderCartActions";
import {
  applyPromoCode,
  clearPromoCodeFlags,
} from "../../redux/actions/PromoActions";
import {
  getPaymentLink,
  clearPaymentFlag,
} from "../../redux/actions/PaymentActions";

import styles from "assets/jss/material-kit-pro-react/views/cart/cartStyles";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "Test Kit",
      qty: 0,
      price: 0,
      total: 0,
      emptyCart: false,
      promoCode: "",
      isPromoCodeApplied: false,
      isLoggedIn: false,
      openSnackBar: false,
      response: "",
      snackBarVariant: "error",
      uuid: localStorage.getItem("uuid"),
      promoSuccess: false,
      promoError: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { getCartDetails, client } = this.props;
    const { uuid } = this.state;
    this.setState({
      isLoggedIn: localStorage.getItem("LoggedIn"),
    });
    getCartDetails({ uuid }, client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      cartQuantity,
      deleteCartError,
      deleteCartSuccess,
      clearCartFlag,
      updateCartSuccess,
      getCartSuccess,
      updateCart,
      clearPromoCodeFlags,
      promoCodeError,
      promoCodeSuccess,
    } = props;

    if (getCartSuccess) {
      clearCartFlag();
      return {
        qty: cartQuantity,
        total: parseInt(cartQuantity) * 65,
      };
    }
    if (updateCartSuccess) {
      clearCartFlag();
      return {
        qty: updateCart.quantity,
        total: parseInt(updateCart.quantity) * 65,
      };
    }
    if (deleteCartError) {
      clearCartFlag();
      return {
        openSnackBar: true,
      };
    }
    if (deleteCartSuccess) {
      clearCartFlag();
      localStorage.removeItem("uuid");
    }

    if (promoCodeSuccess) {
      clearPromoCodeFlags();
      return {
        promoSuccess: true,
      };
    }

    if (promoCodeError) {
      clearPromoCodeFlags();
      return {
        promoError: true,
      };
    }

    return null;
  }

  addQty = () => {
    const { updateCartDetails, client } = this.props;
    const { qty, uuid } = this.state;

    this.setState(
      {
        qty: parseInt(qty) + 1,
        total: parseInt(qty + 1) * 65,
      },
      () => {
        const updatedDetails = {
          uuid: uuid,
          product_id: 2,
          quantity: parseInt(qty) + 1,
        };
        updateCartDetails(updatedDetails, client);
      }
    );
  };

  decreaseQty = () => {
    const { updateCartDetails, client } = this.props;
    const { qty, uuid } = this.state;

    if (this.state.qty > 1) {
      this.setState(
        {
          qty: this.state.qty - 1,
          total: (this.state.qty - 1) * 65,
        },
        () => {
          const updatedDetails = {
            uuid: uuid,
            product_id: 2,
            quantity: parseInt(qty) - 1,
          };

          updateCartDetails(updatedDetails, client);
        }
      );
    }
  };

  deleteItem = () => {
    const { uuid } = this.state;
    const { deleteCartDetails, client } = this.props;
    deleteCartDetails({ uuid }, client);
  };

  goToPayment = () => {
    const { total, qty, uuid } = this.state;
    this.props.history.push(`/payment`, {
      cartUuid: uuid,
      total: total,
      qty: qty,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  promoCodeApplied = () => {
    this.setState({
      isPromoCodeApplied: true,
    });
  };

  addPromoCode = () => {
    const { applyPromoCode, client } = this.props;
    const { promoCode, total } = this.state;
    const promoCodeData = {
      promo_code: promoCode,
      total_amount: total,
    };
    applyPromoCode(promoCodeData, client);
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const {
      classes,
      cartQuantity,
      productData,
      deleteCartSuccess,
    } = this.props;
    const {
      qty,
      total,
      isPromoCodeApplied,
      promoCode,
      productName,
      openSnackBar,
      promoSuccess,
      promoError,
    } = this.state;
    return (
      <>
        <Suspense fallback={<div />}>
          <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />
            <Snackbar
              open={openSnackBar}
              handleClose={this.handleCloseSnackbar}
              variant="error"
              message="Something went wrong, try again later!"
            />
            <div className={classes.divFlex}>
              <div className={classes.container}>
                {!deleteCartSuccess ? (
                  <>
                    <h3 className={classes.titleCenter}> Order Summary </h3>
                    <BrowserView>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className={classes.tableOverflow}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell className={classes.tableCell}>
                                  Unit Price
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Quantity
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <img
                                    src={img}
                                    alt="img"
                                    style={{ height: "100px" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <h4
                                    className={classes.productName}
                                    style={{ marginBottom: 0 }}
                                  >
                                    {productName}
                                  </h4>
                                  <p className={classes.flexP}>
                                    <Tooltip title="Remove Item Form Cart">
                                      <Button
                                        color="transparent"
                                        style={{ padding: 0, margin: 0 }}
                                        className={classes.remove}
                                        onClick={this.deleteItem}
                                      >
                                        <Delete
                                          className={
                                            classes.font_size +
                                            " " +
                                            classes.iconColor
                                          }
                                        />
                                        <span style={{ marginTop: "3px" }}>
                                          {" "}
                                          Remove{" "}
                                        </span>
                                      </Button>
                                    </Tooltip>
                                  </p>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  &euro; 65
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    onClick={this.decreaseQty}
                                    style={{ boxShadow: "none" }}
                                  >
                                    <Remove />
                                  </Button>
                                  <span> {qty} </span>
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    onClick={this.addQty}
                                    style={{ boxShadow: "none" }}
                                  >
                                    <Add />
                                  </Button>
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{ textAlign: "right" }}
                                >
                                  <span className={classes.total}>
                                    &euro; {total}{" "}
                                  </span>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </GridItem>
                      </GridContainer>
                    </BrowserView>

                    <MobileView>
                      <div className={classes.tableShadow}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.paddingRight}>
                                <img
                                  src={img}
                                  alt="img"
                                  style={{ height: "80px" }}
                                />
                              </TableCell>
                              <TableCell className={classes.cellTestkit}>
                                <div className={classes.divFlexTitle}>
                                  <h4 className={classes.productName}>
                                    {productName}
                                  </h4>
                                  <p
                                    className={
                                      classes.productName +
                                      " " +
                                      classes.mobileProductPrice
                                    }
                                  >
                                    &euro; 65
                                  </p>
                                </div>

                                <div>
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    onClick={this.decreaseQty}
                                    style={{
                                      boxShadow: "none",
                                      padding: 4,
                                      border: "1px solid lightgray",
                                    }}
                                  >
                                    <Remove />
                                  </Button>
                                  <span className={classes.qty}> {qty} </span>
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    onClick={this.addQty}
                                    style={{
                                      boxShadow: "none",
                                      padding: 4,
                                      border: "1px solid lightgray",
                                    }}
                                  >
                                    <Add />
                                  </Button>
                                </div>
                                <p
                                  className={classes.flexP}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Tooltip title="Remove Item Form Cart">
                                    <Button
                                      color="transparent"
                                      style={{ padding: 0, margin: 0 }}
                                      className={classes.remove}
                                      onClick={this.deleteItem}
                                    >
                                      <Delete
                                        className={
                                          classes.font_size +
                                          " " +
                                          classes.iconColor
                                        }
                                      />
                                      <span style={{ marginTop: "3px" }}>
                                        {" "}
                                        Remove{" "}
                                      </span>
                                    </Button>
                                  </Tooltip>
                                </p>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell
                                style={{ paddingLeft: "0", textAlign: "right" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  Total ({cartQuantity && cartQuantity} Items) :
                                  {cartQuantity && (
                                    <span
                                      className={
                                        promoSuccess
                                          ? classes.totalMobile +
                                            " " +
                                            classes.discountedPrice
                                          : classes.totalMobile
                                      }
                                    >
                                      &euro; {total}{" "}
                                    </span>
                                  )}
                                </div>
                                {promoSuccess && productData && (
                                  <span
                                    className={
                                      classes.totalMobile +
                                      " " +
                                      classes.priceAfterDiscount
                                    }
                                  >
                                    {" "}
                                    &euro; {productData.user_pay_amount}{" "}
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <Shipping className={classes.iconColor} />{" "}
                                  <span style={{ marginLeft: "10px" }}>
                                    Free Shipping !
                                  </span>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        <div className="promoCodeDiv">
                          <div className={classes.mobilePromoCode}>
                            <Button
                              color="transparent"
                              className={classes.promoCodeButton}
                              onClick={this.promoCodeApplied}
                            >
                              <span>Apply Promo Code</span>
                              <AddIcon className={classes.promoAddIcon} />
                            </Button>
                          </div>
                          {isPromoCodeApplied && (
                            <>
                              <div className={classes.promoCodeInput}>
                                <TextField
                                  label="Promo Code"
                                  name="promoCode"
                                  variant="outlined"
                                  margin="normal"
                                  size="small"
                                  onChange={this.handleChange}
                                  value={promoCode}
                                  style={{
                                    marginTop: "8px",
                                  }}
                                />
                                <Button onClick={this.addPromoCode}>
                                  Apply
                                </Button>
                              </div>
                              <div className={classes.discountMobile}>
                                {promoSuccess && productData && (
                                  <p>
                                    Discount :{" "}
                                    <span>
                                      {" "}
                                      &euro; {productData.discount_amount}{" "}
                                    </span>
                                  </p>
                                )}
                                {promoError && (
                                  <p>Please Apply Valid Promo Code!</p>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <Button
                          color="theme"
                          fullWidth
                          variant="contained"
                          onClick={this.goToPayment}
                        >
                          Checkout
                        </Button>
                      </div>
                    </MobileView>

                    <BrowserView>
                      <GridContainer justify="space-around">
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <Card className={classes.customCard}>
                            <CardBody>
                              <div className="promoCodeDiv">
                                <div className={classes.desktopPromoCode}>
                                  <Button
                                    color="transparent"
                                    className={classes.promoCodeButton}
                                  >
                                    Apply Promo Code
                                  </Button>
                                </div>
                                <div className={classes.promoCodeInput}>
                                  <TextField
                                    label="Promo Code"
                                    name="promoCode"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    onChange={this.handleChange}
                                    value={promoCode}
                                    style={{
                                      marginTop: "8px",
                                    }}
                                  />
                                  <Button
                                    color="theme"
                                    style={{ marginLeft: "15px" }}
                                    onClick={this.addPromoCode}
                                  >
                                    Apply
                                  </Button>
                                </div>
                                {promoSuccess && (
                                  <div className={classes.discountDesktop}>
                                    <p>
                                      Discount :{" "}
                                      <span>
                                        {" "}
                                        &euro; {
                                          productData.discount_amount
                                        }{" "}
                                      </span>
                                    </p>
                                  </div>
                                )}
                                {promoError && (
                                  <div className={classes.discountDesktop}>
                                    <p>Please Apply Valid Promo Code!</p>
                                  </div>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <Card className={classes.customCard}>
                            <CardBody>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        Total ({qty} Items) :
                                        {cartQuantity && (
                                          <span
                                            className={
                                              promoSuccess
                                                ? classes.totalMobile +
                                                  " " +
                                                  classes.discountedPrice
                                                : classes.totalMobile
                                            }
                                          >
                                            &euro; {total}{" "}
                                          </span>
                                        )}
                                        {promoSuccess && (
                                          <span
                                            className={
                                              classes.totalMobile +
                                              " " +
                                              classes.priceAfterDiscount
                                            }
                                          >
                                            {" "}
                                            &euro; {
                                              productData.user_pay_amount
                                            }{" "}
                                          </span>
                                        )}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ borderBottom: "0" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Shipping
                                          className={classes.iconColor}
                                        />{" "}
                                        <span style={{ marginLeft: "15px" }}>
                                          Free Shipping !
                                        </span>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </CardBody>
                            <CardFooter>
                              <Button
                                color="theme"
                                fullWidth
                                variant="contained"
                                onClick={this.goToPayment}
                              >
                                Checkout
                              </Button>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </BrowserView>
                  </>
                ) : (
                  <>
                    <GridContainer justify="center">
                      <GridItem md={6} sm={12} xs={12}>
                        <div className={classes.gridCart}>
                          <GridContainer justify="center">
                            <GridItem md={12} sm={12} xs={12}>
                              <div style={{ textAlign: "center" }}>
                                <p className={classes.emptyCart}>
                                  Your cart is empty
                                </p>
                                <p className={classes.addIntoCart}>
                                  Please add something in your cart
                                </p>
                                <Link to="/test-kit-details">
                                  <Button
                                    color="theme"
                                    variant="contained"
                                    className={classes.shoppingButton}
                                  >
                                    Continue Shopping
                                  </Button>
                                </Link>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Cart.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    cartQuantity:
      state.cartReducer &&
      state.cartReducer.cartDetails &&
      state.cartReducer.cartDetails.quantity,
    uuid:
      state.cartReducer &&
      state.cartReducer.addCartData &&
      state.cartReducer.addCartData.uuid,
    getCartSuccess: state.cartReducer && state.cartReducer.getCartSuccess,
    deleteCartSuccess: state.cartReducer && state.cartReducer.deleteCartSuccess,
    deleteCartError: state.cartReducer && state.cartReducer.deleteCartError,
    productData: state.promoCodeReducer && state.promoCodeReducer.productData,
    loggedIn: state.logInReducer && state.logInReducer.loggedIn,
    getLinkSuccess: state.paymentReducer && state.paymentReducer.getLinkSuccess,
    paymentLink: state.paymentReducer && state.paymentReducer.paymentLink,
    updateCartSuccess: state.cartReducer && state.cartReducer.updateCartSuccess,
    updateCart: state.cartReducer && state.cartReducer.updateCart,
    promoCodeSuccess:
      state.promoCodeReducer && state.promoCodeReducer.promoCodeSuccess,
    promoCodeError:
      state.promoCodeReducer && state.promoCodeReducer.promoCodeError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCartDetails,
      updateCartDetails,
      deleteCartDetails,
      applyPromoCode,
      getPaymentLink,
      clearPaymentFlag,
      clearCartFlag,
      clearPromoCodeFlags,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(Cart)));
