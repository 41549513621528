/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";
import GoogleLogin from "react-google-login";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Snackbar = lazy(() =>
  import("../../components/Snackbar/SnackbarContent")
);

import image from "../../assets/img/subscribe1.svg";

import ReactFacebookLoginWithButton from "./FacebookButton";
import { socialLogin } from "../../services/SocialLogin";
import {
  logIn,
  verifyUser,
  clearLoginFlag,
} from "../../redux/actions/LoginActions";
import {
  createCart,
  clearCartFlag,
} from "../../redux/actions/OrderCartActions";

import styles from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showError: false,
      fromCartIn: false,
      cartData: null,
      openSnackBar: false,
      response: "",
      snackBarVariant: "error",
      passwordVisibility: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const tokenFromUrl = window.location.href.split("=")[1];

    const { verifyUser } = this.props;
    const token = {
      token: tokenFromUrl,
    };

    if (token && token.token) {
      verifyUser(token);
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    const {
      loginSuccess,
      clearLoginFlag,
      loginData,
      loginError,
      createCart,
      clearCartFlag,
      location: { state },
      client,
    } = props;
    const { cartData, fromCartIn } = prevState;

    if (loginSuccess) {
      clearLoginFlag();
      localStorage.setItem("LoggedIn", true);
      localStorage.setItem("userName", loginData.user.name);

      if (fromCartIn) {
        clearCartFlag();
        createCart(cartData, client);
        props.history.push("/cart", { fromCart: true });
      } else {
        props.history.push("/my-profile/you");
      }
    }

    if (state && state.hasOwnProperty("fromCartIn") && state.fromCartIn) {
      return {
        cartData: state.cartData,
        fromCartIn: state.fromCartIn,
      };
    }

    if (loginError) {
      clearLoginFlag();
      return {
        showError: true,
      };
    }

    return null;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePasswordVisibility = () => {
    const { passwordVisibility } = this.state;
    this.setState({
      passwordVisibility: !passwordVisibility,
    });
  };

  goToWelcomePage = (values) => {
    const { email, password } = values;
    const { logIn, client } = this.props;

    const logInData = {
      email,
      password,
    };
    logIn(logInData, client);
  };

  responseFacebook = (response) => {
    if (response.accessToken) {
      const { fromCartIn, cartData } = this.state;
      const { createCart, clearCartFlag, client } = this.props;
      const data = {
        provider: "facebook",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            if (fromCartIn) {
              createCart(cartData, client);
              clearCartFlag();
              this.props.history.push("/cart", { fromCart: true });
            } else {
              this.props.history.push("/self-assessment-test-details");
            }
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  responseGoogle = (response) => {
    const { fromCartIn, cartData } = this.state;
    const { createCart, clearCartFlag, client } = this.props;

    if (response.accessToken) {
      const data = {
        provider: "google",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            if (fromCartIn) {
              createCart(cartData, client);
              clearCartFlag();
              this.props.history.push("/cart", { fromCart: true });
            } else {
              this.props.history.push("/self-assessment-test-details");
            }
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const { classes, isLoginLoading } = this.props;
    const {
      email,
      password,
      openSnackBar,
      response,
      snackBarVariant,
      showError,
      passwordVisibility,
    } = this.state;
    return (
      <div>
        <Suspense fallback={<div />}>
          <div>
            <Snackbar
              open={openSnackBar}
              handleClose={this.handleCloseSnackbar}
              variant={snackBarVariant}
              message={response}
            />
            <Header
              fixed
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />
            <div className={classes.loginMainDiv}>
              <div
                className={classes.container + " " + classes.loginPageContainer}
              >
                <GridContainer justify="center" className="selfAssessmentForm">
                  <GridItem xs={12} sm={6} md={5}>
                    <img
                      src={image}
                      alt="self-assessment"
                      className={classes.loginImage}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}></GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <h2 className={classes.cardTitle}>Sign In</h2>
                    <Card className={classes.cardDiv}>
                      <form className={classes.form}>
                        <Formik
                          enableReinitialize={true}
                          validationSchema={Yup.object().shape({
                            email: Yup.string()
                              .email("Email is invalid")
                              .required("Email is required"),
                            password: Yup.string().required(
                              "Password is required"
                            ),
                          })}
                          initialValues={{
                            email,
                            password,
                          }}
                          onSubmit={(values) => {
                            this.goToWelcomePage(values);
                          }}
                        >
                          {({
                            errors,
                            values,
                            touched,
                            handleSubmit,
                            handleChange,
                          }) => (
                            <>
                              <CardBody signup>
                                <div>
                                  <TextField
                                    fullWidth
                                    placeholder={"Email"}
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    autoComplete="email"
                                    margin="normal"
                                    variant="outlined"
                                    value={values.email}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton>
                                            <MailIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {errors.email && touched.email && (
                                    <FormHelperText error>
                                      {errors.email}
                                    </FormHelperText>
                                  )}
                                </div>
                                <div>
                                  <TextField
                                    fullWidth
                                    placeholder={"Password"}
                                    name="password"
                                    type={
                                      passwordVisibility ? "text" : "password"
                                    }
                                    onChange={handleChange}
                                    autoComplete="email"
                                    margin="normal"
                                    variant="outlined"
                                    value={values.password}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={
                                              this.handlePasswordVisibility
                                            }
                                          >
                                            {passwordVisibility ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {errors.password && touched.password && (
                                    <FormHelperText error>
                                      {errors.password}
                                    </FormHelperText>
                                  )}
                                </div>
                                {showError && (
                                  <p className={classes.error}>
                                    Invalid Username and Password
                                  </p>
                                )}
                              </CardBody>
                              <div className={classes.textCenter}>
                                <Button
                                  color="theme"
                                  style={{ marginBottom: 30, marginTop: 20 }}
                                  onClick={handleSubmit}
                                >
                                  {isLoginLoading ? (
                                    <span className="btn-spinner" />
                                  ) : (
                                    "Sign In"
                                  )}
                                </Button>
                              </div>
                            </>
                          )}
                        </Formik>
                        <div className={classes.divContent}>
                          <span>
                            {" "}
                            Did not have any account?{" "}
                            <Link to="/sign-up" style={{ color: "inherit" }}>
                              {" "}
                              Click Here{" "}
                            </Link>{" "}
                          </span>
                        </div>
                        <p className={classes.divContent}>
                          <span>or You Can Log in With</span>
                        </p>
                        <div className={classes.socialButtons}>
                          <ul className={classes.socialButtons}>
                            <li>
                              <ReactFacebookLoginWithButton
                                appId={process.env.REACT_APP_FACE_BOOK_APP_ID}
                                autoLoad={false}
                                redirectUri={
                                  window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/sign-in"
                                }
                                fields="name,email,picture"
                                callback={this.responseFacebook}
                                // isMobile={false}
                                cssClass="facebook-button"
                                icon={
                                  <Button justIcon simple color="facebook">
                                    <i className="fab fa-facebook-square" />
                                  </Button>
                                }
                              />
                            </li>
                            <li>
                              <GoogleLogin
                                clientId={process.env.REACT_APP_GMAIL_CLIENT_ID}
                                autoLoad={false}
                                render={(renderProps) => (
                                  <Button
                                    justIcon
                                    simple
                                    color="google"
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                  >
                                    <i className="fab fa-google" />
                                  </Button>
                                )}
                                buttonText=""
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={"single_host_origin"}
                              />
                            </li>
                          </ul>
                        </div>
                        <div></div>
                      </form>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </Suspense>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    verifySuccess: state.logInReducer.verifySuccess,
    userData: state.logInReducer && state.logInReducer.userData,
    loginSuccess: state.logInReducer && state.logInReducer.loginSuccess,
    loginData: state.logInReducer && state.logInReducer.loginData,
    errorData: state.logInReducer && state.logInReducer.errorData,
    loginError: state.logInReducer && state.logInReducer.loginError,
    isLoginLoading: state.logInReducer && state.logInReducer.isLoginLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logIn,
      verifyUser,
      clearLoginFlag,
      createCart,
      clearCartFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(LoginPage)));
