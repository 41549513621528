import gql from "graphql-tag";

export const GetPaymentLink = gql`
  mutation Payment(
    $productName: String
    $quantity: Int
    $price: Int
    $description: String
    $total: Int    
  ) {
    GetPaymentLink(
      productName: $productName
      quantity: $quantity
      price: $price
      description: $description
      total: $total      
    ) {
      paypal_link
    }
  }
`;
