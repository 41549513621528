import {
  // primaryColor,
  grayColor,
  container,
  cardTitle,
  whiteColor,
  blackColor,
  hexToRgb,
  primaryMainColor,
  secondaryDarkColor
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    color: whiteColor
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    },
    "&:after": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.15)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  cardSignup: {
    borderRadius: "6px !important",
    boxShadow: "none !important",
    padding: "80px 0px !important"
  },
  cardTitle: {
    ...cardTitle,
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem",
    color: primaryMainColor + "!important",
    lineHeight: "80px",
    fontFamily: "inherit !important"
  },
  ...customCheckboxRadioSwitchStyle,
  socials: {
    marginTop: "0",
    position: "absolute",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  textCenter: {
    textAlign: "center"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: grayColor[13]
  },
  form: {
    margin: "0"
  },
  infoArea: {
    padding: "0px 0px 20px !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: " #607d8b"
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  divLine: {
    backgroundColor: primaryMainColor,
    height: "100px",
    width: "85%",
    position: "absolute",
    top: "-50px"
  },
  divWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  assessmentTitle: {
    textAlign: "center",
    fontWeight: "400",
    marginBottom: "60px",
    color: secondaryDarkColor
  },
  flexBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    alignItems: "center",
    flexWrap: "wrap"
  },
  socialButtons: {
    textAlign: "center",
    "& ul": {
      display: "inline",
      paddingLeft: "0px",
      "& li": {
        display: "inline-block"
      }
    }
  },
  titleCenter: {
    textAlign: "left",
    fontWeight: "700",
    color: secondaryDarkColor
  },
  cartTitle: {
    textAlign: "left",
    fontWeight: "700",
    color: secondaryDarkColor,
    marginLeft: "10px",
    marginTop: "20px"
  },
  border: {
    borderBottom: "none"
  },
  pb0: {
    paddingBottom: 0
  },
  marginTop: {
    marginTop: 0,
    boxShadow: "none",
    "@media (max-width: 1024px)": {
      marginTop: "40px"
    }
  },
  tableOverflow: {
    overflowX: "auto"
  },
  textAlign: {
    textAlign: "right"
  },
  gridShadow: {
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    padding: "30px 50px"
  },
  shadow: {
    boxShadow: "1px 1px 10px 1px #0000001f",
    marginLeft: "100px",
    marginRight: "100px",
    "@media (max-width: 1024px)": {
      marginLeft: "0",
      marginRight: "0"
    }
  },
  paddingContainer: {
    paddingTop: "15vh",
    "@media (max-width: 1024px)": {
      paddingTop: "5vh"
    }
  },
  signUpMainCard: {
    backgroundColor: whiteColor,
    minHeight: "100vh"
  },
  signUpContainer: {
    paddingTop: "18vh",
    "@media (max-width: 768px)": {
      paddingTop: "5vh"
    }
  },
  signUpImage: {
    width: "100%",
    marginTop: "50px",
    "@media (max-width: 768px)": {
      marginTop: "20px"
    }
  },
  askAssessmentButton: {
    width: "150px",
    fontSize: "0.9rem",    
  }
};

export default signupPageStyle;
