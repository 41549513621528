import gql from "graphql-tag";

//Create Cart
export const CreateCart = gql`
  mutation CreateCart($product_id: Int, $quantity: Int) {
    CreateCart(product_id: $product_id, quantity: $quantity) {
      uuid
      product_id
      quantity
      user_id
      creator_id
      updater_id
      user {
        name
      }
      product {
        name
      }
    }
  }
`;

//Update Cart
export const UpdateCart = gql`
  mutation UpdateCart($uuid: String!, $product_id: Int, $quantity: Int) {
    UpdateCart(uuid: $uuid, product_id: $product_id, quantity: $quantity) {
      product_id
      quantity
      user_id
      creator_id
      updater_id
      user {
        name
      }
      product {
        name
      }
    }
  }
`;

//Delete Cart
export const DeleteCart = gql`
  mutation DeleteCart($uuid: String!) {
    DeleteCart(uuid: $uuid) {
      uuid
      message
    }
  }
`;
