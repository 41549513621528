import {
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_ERROR,
  CLEAR_PROMO_CODE_FLAG
} from "../type/PromoType";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  productData: "",
  promoCodeSuccess: false,
  promoCodeError: false
};

export default function promoCodeReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: 
      if (action.payload && action.payload.promoCodeReducer) {
        return { ...action.payload.promoCodeReducer };
      }

      return {
        ...state
      };
    case APPLY_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCodeSuccess: false,
        promoCodeError: false
      };
    case APPLY_PROMO_CODE_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        promoCodeSuccess: true,
        promoCodeError: false,
        productData: payload.data.ApplyPromoCode
      };
    case APPLY_PROMO_CODE_ERROR:
      return {
        ...state,
        promoCodeSuccess: false,
        promoCodeError: true
      };
    case CLEAR_PROMO_CODE_FLAG:
      return {
        promoCodeSuccess: false,
        promoCodeError: false
      };
    default:
      return state;
  }
}
