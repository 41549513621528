import {
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_ERROR,
  CLEAR_PROMO_CODE_FLAG,
} from "../type/PromoType";
import { ApplyPromoCode } from "../../graphql/Mutations/PromoMutation";

export const promoCodeRequest = payload => {
  return {
    type: APPLY_PROMO_CODE_REQUEST,
    payload
  };
};

export const promoCodeSuccess = payload => {
  return {
    type: APPLY_PROMO_CODE_SUCCESS,
    payload
  };
};

export const promoCodeError = payload => {
  return {
    type: APPLY_PROMO_CODE_ERROR,
    payload
  };
};

export const applyPromoCode = (values, client) => {
  return dispatch => {
    dispatch(promoCodeRequest());
    return client
      .mutate({
        variables: values,
        mutation: ApplyPromoCode
      })
      .then(result => dispatch(promoCodeSuccess(result)))
      .catch(error => dispatch(promoCodeError(error)));
  };
};

export const clearPromoCodeFlags = () => {
  return {
    type: CLEAR_PROMO_CODE_FLAG
  }
}