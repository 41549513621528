/* eslint-disable */
import React, { lazy, Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Header = lazy(() => import("components/Header/Header.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const SectionCards = lazy(() => import("views/PresentationPage/Sections/SectionCards.js"));
const Team = lazy(() => import("./Team.js"));

import { AboutUSDesc } from "constants/Data.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const myId = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView();
    }
  });
  const classes = useStyles();
  return (
    <>
      <Suspense fallback={<div />}>
        <div>
          <Header
            brand="Material Kit PRO React"
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 300,
              color: "white"
            }}
          />
          <Parallax
            image={require("assets/img/aboutUs-banner.jpg")}
            className={classes.parallax}
          />

          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              <div
                className={classNames(classes.aboutDescription, classes.textCenter)}
              >
                <h2 className={classes.title}> About Us </h2>
                <GridContainer>
                  <GridItem
                    md={10}
                    sm={8}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                  >
                    {AboutUSDesc.map((description, index) => (
                      <p className={classes.description}>{description.desc}</p>
                    ))}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <div className={classes.sectionCards}>
              <SectionCards />
            </div>
            <div id="Team" className={classes.sectionTeam}>
              <Team />
            </div>
            {/* <div>
                    <Testimonials />
                </div>
                <div>
                    <SectionFreeDemo />
                </div> */}
            <Footer />
          </div>
        </div>
      </Suspense>
    </>
  );
}
