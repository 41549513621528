/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const Card = lazy(() => import("components/Card/Card"));
const CardBody = lazy(() => import("components/Card/CardBody"));
const Footer = lazy(() => import("components/Footer/Footer"));

import {
  primaryMainColor,
  blackColor,
  secondaryLightColor,
} from "assets/jss/material-kit-pro-react.js";

const styles = {
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  divCard: {
    boxShadow: "none !important",
  },
  cardTitle: {
    textAlign: "center",
    fontSize: "2.2rem",
    fontWeight: "500",
    color: primaryMainColor,
  },
  cardDesc: {
    fontSize: "1rem",
    color: secondaryLightColor,
    textAlign: "justify",
    letterSpacing: "0.5px !important",
    marginTop: 20,
  },
  gridContainer: {
    marginTop: "5rem",
  },
  gridTitle: {
    fontSize: "2.6rem",
    fontWeight: "500",
    color: primaryMainColor,
    textAlign: "center",
    marginBottom: "2rem",
  },
  gridItem: {
    textAlign: "center",
  },
  title: {
    color: "blackColor",
    fontWeight: "400",
  },
  gridContent: {
    color: "#828381",
  },
  testDetailButtonCenter: {
    textAlign: "center",
  },
  testDetailsSubTitle: {
    fontWeight: "500",
    color: primaryMainColor,
    marginBottom: 30,
    marginTop: 40,
  },
  ScoreDescription: {
    fontSize: "1rem",
    color: secondaryLightColor,
    "& span": {
      fontWeight: 500,
      marginRight: 10,
      color: blackColor,
    },
  },
  boldText: {
    fontWeight: 500,
  },
};

class TestResponse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goToQuestion = () => {
    this.props.history.push("/question");
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Suspense fallback={<div />}>
          <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            />
            <div>
              <div className={classes.container} style={{ paddingTop: "12vh" }}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <Card className={classes.divCard}>
                      <CardBody>
                        <div>
                          <h1 className={classes.cardTitle}>
                            {" "}
                            Aging Male Symptoms Score (AMS){" "}
                          </h1>
                          <p className={classes.cardDesc}>
                            The Aging Male Symptoms Score (AMS) is the most
                            commonly used scale worldwide to measure
                            health-related quality of life and symptoms in aging
                            males.
                          </p>
                          <p className={classes.cardDesc}>
                            The AMS questionnaire itself (or any other
                            questionnaire) does not constitute a test or
                            diagnosis of testosterone deficiency. A diagnosis of
                            testosterone deficiency is based on symptoms that
                            are indicative of the deficient of the hormone (the
                            AMS questionnaire can be used for symptom
                            evaluation), combined with low testosterone levels
                            as detected by mandatory blood testing.
                          </p>
                          <p className={classes.cardDesc}>
                            The AMS scale is also a valuable tool to measure
                            effects of testosterone treatment on symptom relief
                            over time which have an important impact on your
                            wellbeing and quality of life.
                          </p>
                          <p className={classes.cardDesc}>
                            If you are concerned that you may have testosterone
                            deficiency, simply score by clicking the{" "}
                            <span style={{ fontWeight: "500" }}>
                              {" "}
                              ‘Start Assessment Test’{" "}
                            </span>{" "}
                            tap.
                          </p>

                          <h5 className={classes.testDetailsSubTitle}>
                            {" "}
                            The implication of the score are as follows:{" "}
                          </h5>

                          <p className={classes.ScoreDescription}>
                            <span> {"<"}26 </span> means you have no significant
                            symptoms consistent with a low testosterone level
                          </p>
                          <p className={classes.ScoreDescription}>
                            <span> 27-36 </span> means you have mild symptoms
                            consistent with a low testosterone level
                          </p>
                          <p className={classes.ScoreDescription}>
                            <span> 37-49 </span> means you have moderate
                            symptoms consistent with a low testosterone level
                          </p>
                          <p className={classes.ScoreDescription}>
                            <span> {">"}50 </span> means you have severe
                            symptoms consistent with a low testosterone level
                          </p>
                          <p
                            className={
                              classes.ScoreDescription + " " + classes.boldText
                            }
                          >
                            If your score is 27 or greater, we would suggest you
                            hormone level be tested.
                          </p>
                        </div>
                        <div className={classes.gridContainer}>
                          <div className={classes.testDetailButtonCenter}>
                            <Button
                              onClick={this.goToQuestion}
                              className={classes.button}
                              variant="contained"
                              style={{
                                marginTop: "30px",
                                backgroundColor: "#0097a7",
                                color: "white",
                              }}
                            >
                              Start Assessment Test
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </div>
    );
  }
}

TestResponse.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestResponse);
