import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EmailIcon from "@material-ui/icons/Email";
import { isMobile, MobileView } from "react-device-detect";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import "react-flags-select/scss/react-flags-select.scss";

import Button from "components/CustomButtons/Button.js";

import { Links } from "constants/Data";
import SelfAssessment from "assets/img/self-assessment-icon.svg";

import styles from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: localStorage.getItem("LoggedIn") || false,
      userName: localStorage.getItem("userName") || "",
      anchorEl: null,
    };
  }

  signOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const { loggedIn, userName, anchorEl } = this.state;
    return (
      <>
        <List
          className={
            classes.list + " " + classes.mlAuto + " " + classes.headerLink
          }
        >
          <ListItem className={classes.listItem}>
            <MobileView style={{ width: "100%" }}>
              <div className={classes.topHeader}>
                <h6
                  style={{
                    textTransform: "lowercase",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 12,
                  }}
                >
                  <EmailIcon style={{ fontSize: 14, marginRight: 10 }} />
                  <a
                    href="mailto: info@menergy.com"
                    style={{ color: "inherit" }}
                  >
                    {" "}
                    info@menergy.com{" "}
                  </a>
                </h6>
                <ul
                  className={classes.socialButtons}
                  style={{ marginBottom: 0, paddingLeft: 0 }}
                >
                  {Links.map((link, index) => (
                    <li key={link.class + index}>
                      <Link to="/">
                        <Button justIcon simple color={link.color}>
                          <i className={link.class} />
                        </Button>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="headerMobile">
                  <ReactFlagsSelect
                    defaultCountry="IE"
                    countries={["IE"]}
                    disabled
                  />
                </div>
              </div>
            </MobileView>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/about-us" className={classes.dropdownLink}>
              <h5> About Us </h5>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/packages" className={classes.dropdownLink}>
              <h5> Packages </h5>
            </Link>
          </ListItem>         
          {!loggedIn && (
            <ListItem
              className={classes.listItem + " " + classes.signOutDesktop}
            >
              <Link to="/sign-in" className={classes.dropdownLink}>
                <h5> Sign in </h5>
              </Link>
            </ListItem>
          )}
          {loggedIn && !isMobile && (
            <>
              <ListItem
                className={classes.listItem + " " + classes.dropdownLink}
                onClick={this.handleClick}
              >
                <h5 style={{ display: "flex", cursor: "pointer" }}>
                  <PersonIcon /> {userName}
                </h5>
              </ListItem>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                elevation={1}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={this.handleClose}>
                  {" "}
                  <Link to="/my-profile/you" className={classes.dropdownLink}>
                    {" "}
                    My Profile{" "}
                  </Link>{" "}
                </MenuItem>
                <MenuItem onClick={this.handleClose}>
                  {" "}
                  <Link to="/history" className={classes.dropdownLink}>
                    {" "}
                    History{" "}
                  </Link>{" "}
                </MenuItem>
                <MenuItem onClick={this.signOut}>
                  {" "}
                  <Link to="/" className={classes.dropdownLink}>
                    {" "}
                    Sign Out{" "}
                  </Link>{" "}
                </MenuItem>
              </Menu>
            </>
          )}
          {loggedIn && isMobile && (
            <>
              <ListItem
                className={classes.listItem + " " + classes.iconMenu}
                onClick={this.handleClick}
              >
                <Link to="/my-profile/you" className={classes.dropdownLink}>
                  <h5 style={{ display: "flex" }}>
                    <PersonIcon /> {userName}
                  </h5>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem} onClick={this.handleClick}>
                <Link to="/my-profile/you" className={classes.dropdownLink}>
                  <h5> My Profile </h5>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem} onClick={this.handleClick}>
                <Link to="/history" className={classes.dropdownLink}>
                  <h5> History </h5>
                </Link>
              </ListItem>
            </>
          )}
          <ListItem className={classes.listItem + " " + classes.iconMenu}>
            <Link to="/assessment" className={classes.dropdownLink}>
              {isMobile ? (
                <h5 style={{ display: "flex" }}>
                  {" "}
                  <ShoppingCart
                    className={classes.ShoppingCartIcon}
                  /> Cart{" "}
                </h5>
              ) : (
                <ShoppingCart className={classes.ShoppingCartIcon} />
              )}
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/self-assessment" className={classes.dropdownLink}>
              <Button
                className={
                  classes.navButton + " " + classes.selfAssessmentButton
                }
                color="theme"
              >
                <img src={SelfAssessment} alt={SelfAssessment} />
                Self Assessment
              </Button>
            </Link>
          </ListItem>
          {loggedIn && isMobile && (
            <ListItem className={classes.listItem} onClick={this.signOut}>
              <Link to="/" className={classes.dropdownLink}>
                <h5> Sign Out </h5>
              </Link>
            </ListItem>
          )}
        </List>
      </>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderLinks);
