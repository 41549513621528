import gql from "graphql-tag";

export const Payment = gql`
mutation Payment(
      $qty: Int
      $productId: Int
      $response: String
  ) {
    DoPayment(
          qty: $qty
          productId: $productId 
          response:$response
    ) {
       payment_id      
    }
  }
`;