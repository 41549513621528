import gql from "graphql-tag";

export const ApplyPromoCode = gql`
  mutation ApplyPromoCode($promo_code: String, $total_amount: Int) {
    ApplyPromoCode(promo_code: $promo_code, total_amount: $total_amount) {
      discount_amount
      user_pay_amount
    }
  }
`;
