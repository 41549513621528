import {} from "assets/jss/material-kit-pro-react";
import {
  container,
  cardTitle,
  title,
  mlAuto,
  mrAuto,
  main,
  whiteColor,
  mainRaised,
  grayColor,
  secondaryMainColor,
  blackColor,
  bodyTextDarkColor,
  secondaryDarkColor,
  bodyTextColor,
  primaryMainColor,
  lightGrayColor,
  borderGrayColor,
} from "assets/jss/material-kit-pro-react.js";
import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const profilePageStyle = {
  container,
  ...imagesStyle,
  ...tooltipsStyle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important",
  },
  cardTitle,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
    },
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: grayColor[0],
  },
  textCenter: {
    textAlign: "center !important",
  },
  name: {
    marginTop: "-80px",
  },

  main: {
    ...main,
  },
  mainRaised: {
    ...mainRaised,
  },
  title: {
    ...title,
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  follow: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  followIcon: {
    width: "20px",
    height: "20px",
  },
  followButton: {
    marginTop: "-28px !important",
  },
  gridItem: {
    ...mlAuto,
    ...mrAuto,
  },
  collections: {
    marginTop: "20px",
  },
  cardBody: {
    display: "flex",
    boxOrient: "vertical",
    boxDirection: "normal",
    flexDirection: "column",
    boxPack: "center",
    justifyContent: "center",
  },
  badge: {
    display: "inline-table",
    margin: "0 auto",
  },
  listUnstyled: {
    paddingLeft: "0",
    listStyle: "none",
    "& > li": {
      padding: "5px 0px",
      fontSize: "1em",
    },
  },
  profileTabs: {
    marginTop: "4.284rem",
    marginBottom: "50px",
  },
  card: {
    textAlign: "left !important",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  parallax: {
    height: "380px !important",
    backgroundPosition: "top center",
  },
  productImage: {
    height: "80px",
  },
  productImageMobile: {
    height: "40px",
    width: "50px",
  },
  profileAppBar: {
    boxShadow: "none !important",
    backgroundColor: "inherit !important",
  },
  profileTabSelected: {
    color: whiteColor + "!important",
    backgroundColor: secondaryMainColor + "!important",
  },
  tableOverflow: {
    width: "100%",
    overflowX: "auto",
  },
  mobileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  mobileHeaderContent: {
    display: "flex",
    alignItems: "center",
    "& div": {
      marginLeft: "15px",
      "& p": {
        marginBottom: "0px",
        fontSize: "18px",
      },
    },
  },
  mobileCard: {
    padding: "20px 0px",
    borderBottom: "1px solid" + borderGrayColor,
  },
  mobileCardBorders: {
    border: "1px solid" + borderGrayColor,
    padding: "0px 10px",
  },
  mobileCardBody: {
    paddingTop: 10,
    paddingBottom: 10,
    "& p": {
      marginBottom: "0px",
    },
  },
  valueContent: {
    color: "#b9b4b4",
  },
  titleContent: {
    marginTop: 10,
  },
  bgTab: {
    backgroundColor: lightGrayColor,
    marginLeft: "10px",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: secondaryMainColor + "!important",
      color: whiteColor,
    },
  },
  bloodTestResultTable: {
    paddingLeft: "50px !important",

    "& p": {
      marginBottom: "0px",
    },
    "& .MuiGrid-item": {
      alignItems: "center",
      display: "flex",
    },
  },
  alignItems: {
    textAlign: "center",
  },
  bloodTestResultResult: {
    padding: "20px 0",
    marginTop: "30px",
    boxShadow: "1px 1px 5px 2px #00000029",
    border: "1px solid" + secondaryMainColor,
  },
  tableData: {
    color: bodyTextColor,
    fontWeight: "bold",
  },
  tableTitle: {
    color: blackColor,
    fontWeight: "bold",
  },
  youTitle: {
    marginTop: "60px",
    marginBottom: "30px",
    "& h3": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "20px",
      },
      "& span": {
        marginRight: "20px",
      }
    },
  },
  tableIcon: {
    "& svg": {
      fill: secondaryMainColor,
      cursor: "pointer",
      "&:hover": {
        fill: secondaryDarkColor,
      },
    },
  },
  medicationCard: {
    boxShadow: "1px 1px 5px 2px #00000029",
    padding: "20px 30px",
    borderTop: "3px solid" + secondaryMainColor,
    border: "1px solid" + secondaryMainColor,
    "& h5": {
      marginBottom: "20px",
      color: secondaryMainColor,
    },
    "& p": {
      marginBottom: "15px",
      fontSize: "15px",
      fontWeight: "500",
      color: bodyTextColor,
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "10px",
      },
    },
  },
  resultCard: {
    boxShadow: "1px 1px 5px 2px #00000029",
    padding: "20px 30px",
    borderTop: "3px solid" + secondaryMainColor,
    border: "1px solid" + secondaryMainColor,
  },
  remindersCard: {
    boxShadow: "1px 1px 5px 2px #00000029",
    padding: "20px 30px",
    borderTop: "3px solid" + secondaryMainColor,
    border: "1px solid" + secondaryMainColor,
    minHeight: "170px",
    // marginTop: "30px",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      minHeight: "190px",
    },
    "& h5": {
      color: secondaryMainColor,
    },
    "& p": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      fontWeight: "bold",
      color: bodyTextColor,
      "& svg": {
        height: "15px",
        width: "15px",
        color: bodyTextColor,
        marginRight: "5px",
      },
      "& span": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  chartGrid: {
    "& .$MuiGrid-item": {
      marginBottom: "30px",
    },
  },
  bloodTestResultResultMobile: {
    padding: "30px",
  },
  iconDivFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "10px",
    marginBottom: "10px",
    borderBottom: "1px solid" + borderGrayColor,
    "& p": {
      marginBottom: "0px",
    },
    "& svg": {
      fill: secondaryMainColor,
      marginLeft: "8px",
    },
  },
  resultContentMobile: {
    marginBottom: "10px",
    "& p": {
      marginBottom: "0px",
      fontWeight: "500",
    },
  },
  resultValueMobile: {
    color: bodyTextColor,
  },
  marginIcon: {
    marginLeft: "8px",
    "@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait)": {
      marginLeft: "0px",
    },
  },
  resultDetailsContainer: {
    minHeight: "60vh",
    padding: "150px 0",
    textAlign: "center",
  },
  settingIconContainer: {
    position: "absolute",
    right: "0px",
    top: "60px",
    backgroundColor: "#0000007d",
    padding: "8px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px 0px 0px 5px",
    cursor: "pointer",
    "& svg": {
      fill: whiteColor,
    },
  },
  iconSections: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "25px",
    "& p": {
      marginLeft: "10px",
    },
  },
  resultCardTitle: {
    color: blackColor,
    fontWeight: "600",
  },
  resultCardValue: {
    color: bodyTextColor,
    fontWeight: "600",
  },
  customIndicator: {
    backgroundColor: "transparent !important",
  },
  readMore: {
    marginTop: "10px",
    "& p": {
      fontSize: "15px",
      color: secondaryMainColor,
      fontWeight: "600",
      textAlign: "left",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
  },
  TableInformationResultSection: {
    "& $resultCard": {
      paddingTop: "30px",
      marginBottom: "20px",
      "& p": {
        marginBottom: "0px",
        color: bodyTextColor,
        fontWeight: "600",
      },
      "& $icon": {
        display: "flex",
        alignItems: "center",
        "& svg": {
          marginRight: "5px",
        },
      },
    },
  },
  titleSection: {
    padding: "0 30px",
    "& p": {
      color: blackColor,
      fontWeight: "600",
    },
  },
  tableReadMoreGrid: {
    marginTop: "10px",
    paddingTop: "20px",
    borderTop: "1px solid" + borderGrayColor,
  },
  tableReadMoreGridTitle: {
    color: bodyTextDarkColor + "!important",
    fontWeight: "600",
    marginBottom: "10px !important",
  },
  tableReadMoreGridValue: {
    color: bodyTextColor + "!important",
    fontWeight: "600",
    marginBottom: "10px !important",
  },
};

export default profilePageStyle;
