import { GetBaseApiEndPoint } from "./../appConfig";
import axios from "axios";
const apibase = GetBaseApiEndPoint();

export async function socialLogin(url, data) {
  return axios
    .get(`${apibase}${url}/${data.provider}?token=${data.token}`)
    .then(res => res.data)
    .catch(error => {
      console.log(error);
    });
}
