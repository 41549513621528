/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Snackbar = lazy(() => import("components/Snackbar/SnackbarContent"));

import { signUp, signClearFlag } from "../../redux/actions/SignUpActions";
import ReactFacebookLoginWithButton from "../LoginPage/FacebookButton";
import { socialLogin } from "../../services/SocialLogin";

import image from "../../assets/img/subscribe1.svg";

import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

class SelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      password: "",
      phone: "",
      confirmPassword: "",
      response:
        "Menergy account has been created successfully. verification email has been sent to registered Email Id.",
      variant: "success",
      openSnackBar: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  static getDerivedStateFromProps(props, state) {
    const { signClearFlag } = props;
    if (props.signUpSuccess) {
      signClearFlag();
      return {
        openSnackBar: true,
      };
    }
    return null;
  }

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  submitAssessmentData = (values) => {
    const { signUp, client } = this.props;
    const { name, email, password, confirmPassword, phone } = values;
    const signUpDetails = {
      name,
      email,
      password,
      password_confirm: confirmPassword,
      mobile_no: phone,
    };
    signUp(signUpDetails, client);
  };

  responseFacebook = (response) => {
    if (response.accessToken) {
      const data = {
        provider: "facebook",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              variant: "error",
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            this.props.history.push("/self-assessment-test-details");
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            variant: "error",
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  responseGoogle = (response) => {
    if (response.accessToken) {
      const data = {
        provider: "google",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              variant: "error",
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            this.props.history.push("/self-assessment-test-details");
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            variant: "error",
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  render() {
    const { classes, isSignUpLoading } = this.props;

    const {
      email,
      name,
      password,
      confirmPassword,
      response,
      variant,
      openSnackBar,
      phone,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <div className={classes.signUpMainCard}>
            <div className="selfAssessmentForm">
              <Header
                absolute
                color="white"
                links={<HeaderLinks dropdownHoverColor="info" />}
              />
              <div>
                <div
                  className={classes.container + " " + classes.signUpContainer}
                >
                  <Snackbar
                    open={openSnackBar}
                    handleClose={this.handleCloseSnackbar}
                    variant={variant}
                    message={response}
                  />
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Card
                        className={classes.cardSignup}
                        style={{ padding: "0" }}
                      >
                        <div style={{ textAlign: "center" }}></div>
                        <CardBody>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                              <img
                                src={image}
                                alt="self-assessment"
                                className={classes.signUpImage}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={1}></GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <h2 className={classes.cardTitle}>Sign Up</h2>
                              <Formik
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                  email: Yup.string()
                                    .email("Email is invalid")
                                    .required("Email is required"),
                                  name: Yup.string().required(
                                    "Name is required"
                                  ),
                                  password: Yup.string().required(
                                    "Password is required"
                                  ),
                                  confirmPassword: Yup.string().required(
                                    "Confirm Password is required"
                                  ),
                                  phone: Yup.string().required(
                                    "Phone number is required"
                                  ),
                                })}
                                initialValues={{
                                  email,
                                  name,
                                  password,
                                  confirmPassword,
                                  phone,
                                }}
                                onSubmit={(values) => {
                                  this.submitAssessmentData(values);
                                }}
                              >
                                {({
                                  errors,
                                  values,
                                  touched,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                  <>
                                    <div className={classes.SignUpFormDiv1}>
                                      <form>
                                        <div>
                                          <TextField
                                            fullWidth
                                            label={"Full Name"}
                                            name="name"
                                            margin="normal"
                                            variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                          />
                                          {errors.name && touched.name && (
                                            <FormHelperText error>
                                              {errors.name}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div>
                                          <TextField
                                            fullWidth
                                            label={"Email"}
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                          />
                                          {errors.email && touched.email && (
                                            <FormHelperText error>
                                              {errors.email}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div>
                                          <TextField
                                            fullWidth
                                            label={"Password"}
                                            name="password"
                                            type="password"
                                            autoComplete="new-password"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={values.password}
                                          />
                                          {errors.password &&
                                            touched.password && (
                                              <FormHelperText error>
                                                {errors.password}
                                              </FormHelperText>
                                            )}
                                        </div>

                                        <div>
                                          <TextField
                                            fullWidth
                                            label={"Confirm Password"}
                                            name="confirmPassword"
                                            type="password"
                                            autoComplete="new-password"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                          />
                                          {errors.confirmPassword &&
                                            touched.confirmPassword && (
                                              <FormHelperText error>
                                                {errors.confirmPassword}
                                              </FormHelperText>
                                            )}
                                        </div>
                                      </form>

                                      <div style={{ marginBottom: 10 }}>
                                        <MuiPhoneNumber
                                          fullWidth
                                          name="phone"
                                          variant="outlined"
                                          onBlur={handleChange}
                                          onChange={handleChange}
                                          defaultCountry={"gb"}
                                          value={values.phone}
                                        />
                                        {errors.phone && touched.phone && (
                                          <FormHelperText error>
                                            {errors.phone}
                                          </FormHelperText>
                                        )}
                                      </div>

                                      <div className={classes.textCenter}>
                                        <Button
                                          fullWidth
                                          onClick={handleSubmit}
                                          className={classes.button}
                                          variant="contained"
                                          color="theme"
                                          style={{
                                            marginTop: "30px",
                                          }}
                                        >
                                          {isSignUpLoading ? (
                                            <span className="btn-spinner" />
                                          ) : (
                                            "Sign Up"
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Formik>
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                  fontWeight: "500",
                                  color: "#1c313a",
                                }}
                              >
                                <span>Already have account? </span>
                                <Link
                                  to="/sign-in"
                                  style={{ color: "inherit" }}
                                >
                                  <span>Sign In</span>
                                </Link>
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                  fontWeight: "500",
                                  color: "#1c313a",
                                }}
                              >
                                or You Can Log in With
                              </p>
                              <div className={classes.socialButtons}>
                                <ul className={classes.socialButtons}>
                                  <li>
                                    <ReactFacebookLoginWithButton
                                      appId={
                                        process.env.REACT_APP_FACE_BOOK_APP_ID
                                      }
                                      autoLoad={false}
                                      redirectUri={
                                        window.location.protocol +
                                        "//" +
                                        window.location.host +
                                        "/sign-in"
                                      }
                                      fields="name,email,picture"
                                      callback={this.responseFacebook}
                                      // isMobile={false}
                                      cssClass="facebook-button"
                                      icon={
                                        <Button
                                          justIcon
                                          simple
                                          color="facebook"
                                        >
                                          <i className="fab fa-facebook-square" />
                                        </Button>
                                      }
                                    />
                                  </li>
                                  <li>
                                    <GoogleLogin
                                      clientId={
                                        process.env.REACT_APP_GMAIL_CLIENT_ID
                                      }
                                      autoLoad={false}
                                      render={(renderProps) => (
                                        <Button
                                          justIcon
                                          simple
                                          color="google"
                                          onClick={renderProps.onClick}
                                          disabled={renderProps.disabled}
                                        >
                                          <i className="fab fa-google" />
                                        </Button>
                                      )}
                                      buttonText=""
                                      onSuccess={this.responseGoogle}
                                      onFailure={this.responseGoogle}
                                      cookiePolicy={"single_host_origin"}
                                    />
                                  </li>
                                </ul>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </>
    );
  }
}
SelfAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    signUpSuccess: state.signUpReducer && state.signUpReducer.signUpSuccess,
    isSignUpLoading: state.signUpReducer && state.signUpReducer.isSignUpLoading,
    verifySuccess: state.logInReducer && state.logInReducer.verifySuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signUp,
      signClearFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(SelfAssessment)));
